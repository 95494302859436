import React from "react";

const ResultsDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        Notre partenariat avec le géant postal a débloqué de nouvelles avenues
        de croissance et d'innovation. En mettant l'accent sur la sécurité,
        l'expérience utilisateur, et l'amélioration continue, nous avons non
        seulement surmonté les défis immédiats mais avons également posé les
        bases d'un avenir digital plus prometteur pour notre client.
        Aujourd'hui, nous sommes considérés comme un partenaire stratégique,
        collaborant étroitement avec divers départements pour piloter et
        implémenter des améliorations significatives.
      </p>
      <div>
        <h5 className="font-bold">Robustesse</h5>
        <p>
          Grâce à la mise à jour de l’intégration avec le payment gateway, nous
          avons réduit de 20% les tickets supports, et avons augmenté d’autant
          la satisfaction client.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Optimisation OPEX Cloud</h5>
        <p>
          La refonte de l'architecture des services sur AWS a permis une
          économie de plus de 30%.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Sécurité</h5>
        <p>
          Suite à un ensemble de recommandations que nous avons fait validé et
          réalisé, nous avons mené, avec le département sécurité, un audit PCI
          DSS. Ce dernier n’a révélé que quelques points mineurs qui ont été
          réglés.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Ouverture et Accessibilité</h5>
        <p>
          L’ouverture de la plateforme aux entreprises a permis au client
          d’augmenter ses revenus. Si nous ne disposons pas de chiffres précis,
          les tarifs entreprise sont 30% supérieurs aux tarifs particuliers.
          Avant l’ouverture aux entreprises, ces dernières n’avaient d’autre
          choix que de contourner et souscrire au service en nom propre.
        </p>
      </div>
    </div>
  );
};

export default ResultsDetails;
