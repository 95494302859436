import React, { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import MainRoutes from "./Router/Root";
import { ThemeProvider } from "@material-tailwind/react";

const AppWrapper: FC = () => (
  <ThemeProvider>
    <Router>
      <MainRoutes />
    </Router>
  </ThemeProvider>
);

export default AppWrapper;
