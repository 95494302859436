import React, { useState } from "react";
import SectionContainer from "../../shared-components/SectionContainer";
import { ChevronUpIcon } from "../../shared-components/icon/ChevronUp";
import { Button } from "../../shared-components/Button";
import TitlePage from "../../shared-components/TitlePage";
import ChevronDownIcon from "../../shared-components/icon/ChevronDown";
import { Form, Formik, useField } from "formik";
interface Service {
  title: string;
  days: number;
  fieldName: string; // New field to uniquely identify the Formik field
}

// Update the services array to include the fieldName for each service
const services: Service[] = [
  { title: "Analysis", days: 10, fieldName: "analysisDays" },
  { title: "UI UX Design", days: 20, fieldName: "designDays" },
  { title: "Front End", days: 30, fieldName: "frontendDays" },
  { title: "DevOps", days: 40, fieldName: "devOpsDays" },
  { title: "Quality Analysis", days: 50, fieldName: "qaDays" },
];
interface InitialValues {
  [key: string]: number | ""; // This allows any string as a key and a number as its value
}

const Pricing: React.FC = () => {
  const [totalValue, setTotalValue] = useState(0);
  const RATE = 300;

  const initialValues: InitialValues = services.reduce((acc, service) => {
    acc[service.fieldName] = "";
    return acc;
  }, {} as InitialValues);
  const handleValueChange = (
    fieldName: string,
    value: number | "", // Value might be number or empty string
    values: InitialValues
  ) => {
    // Convert all entries to numbers, treating empty strings as zero
    const updatedValues = { ...values, [fieldName]: value === "" ? 0 : value };

    // Compute the total by reducing over the object values, ensuring all are treated as numbers
    const total = Object.values(updatedValues).reduce<number>(
      (sum, currentValue) => {
        // Convert currentValue safely to a number, using 0 for non-numeric values
        const numericValue =
          currentValue === "" ? 0 : parseFloat(currentValue.toString());
        // Check if numericValue is NaN, if so, use 0 instead to avoid NaN in calculations
        return sum + (isNaN(numericValue) ? 0 : numericValue) * RATE;
      },
      0
    );

    setTotalValue(total); // Assuming setTotalValue is a function to update state
  };

  return (
    <SectionContainer>
      <div className="md:pb-32 pb-16">
        <TitlePage>Evaluer mon projet</TitlePage>
        <div className="grid lg:grid-cols-2 lg:gap-64 gap-12 ">
          <div className=" flex flex-col lg:gap-12 gap-6">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                console.log("Submitted Values:", values);
              }}
            >
              {(formikProps) => (
                <Form className="flex flex-col gap-1">
                  {services.map((service) => (
                    <PriceItem
                      key={service.fieldName}
                      title={service.title}
                      fieldName={service.fieldName}
                      onValueChange={(fieldName, value) => {
                        formikProps.setFieldValue(fieldName, value); // Set the value
                        handleValueChange(fieldName, value, formikProps.values); // Calculate total
                      }}
                    />
                  ))}
                </Form>
              )}
            </Formik>
            <div>
              <p>Total:</p>
              <p className="font-semibold text-4xl">{totalValue} EUR</p>
            </div>
            <div>
              <Button
                description="Prendre rendez-vous"
                link="https://calendly.com/habib-tafo"
                target="_blank"
              />
            </div>

            <p className="text-xs">
              (*) Ceci est un outil de simulation, et n’a aucune valeur
              contractuelle. Merci de nous contacter pour discuter de votre
              projet, notre équipe se chargera d’établir une évaluation.
            </p>
          </div>
          <div className="flex flex-col ">
            <h3 className="font bold text-xl font-bold lg:mb-8 mb-4">
              Nos Offres
            </h3>
            <div className="">
              <p className="text-[28px] leading-[36px]   ">
                Equipe de développement As A Service
              </p>
              <p className="mt-6 ">
                Pour les entreprises qui recherchent une équipe pour compléter
                un projet dans un délai court ou qui ont besoin de capacités
                supplémentaires ponctuelles. Ou à la recherche d'une équipe
                dédiée pour s'attaquer à leur important backlog de produits. Un
                partenaire long terme, qui développe une expertise sur vos
                métiers.
              </p>
            </div>
            <div className="">
              <p className="text-[28px] leading-[36px] lg:mt-8 mt-4  ">
                Service Desk As A Service
              </p>
              <p className="mt-6 ">
                Pour les entreprises dédiées à offrir un service fiable à leurs
                utilisateurs finaux. Notre Service Desk fournit des SLA
                exigeants sur une base 24/7, avec une culture de résolution
                d'incidents centrée sur l'utilisateur final.
              </p>
            </div>
            <div className="lg:mt-16 mt-8">
              <p className="font-bold text-xl mb-6">Nos engagements</p>
              <ul className="grid gap-8 ">
                <li className="flex gap-3 items-center ">
                  <div className="w-2.5 bg-black rounded-full h-2.5"></div>
                  <p className="max-w-[95%] ">
                    {" "}
                    Garantie satisfaction, corrections à nos frais en cas de
                    malfaçons
                  </p>
                </li>
                <li className="flex gap-3 items-center ">
                  <div className="w-2.5 bg-black rounded-full h-2.5"></div>
                  <p className="max-w-[95%] ">
                    {" "}
                    Un interlocuteur senior, qualifié, qui suit votre projet
                    avec vous
                  </p>
                </li>
                <li className="flex gap-3 items-center ">
                  <div className="w-2.5 bg-black rounded-full h-2.5"></div>
                  <p className="max-w-[95%] ">
                    {" "}
                    Haut niveau de service avec des SLAs support exigeants
                  </p>
                </li>
                <li className="flex gap-3 items-center ">
                  <div className="w-2.5 bg-black rounded-full h-2.5"></div>
                  <p className="max-w-[95%] ">
                    {" "}
                    Visibilité budget/réalisation, avec du reporting mensuel
                  </p>
                </li>
              </ul>
            </div>
            <div className="lg:mt-8 mt-4 ">
              <Button description="Parler à un commercial" link="/contact-us" />
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

interface PriceItemProps {
  title: string;
  fieldName: string;
  onValueChange: (fieldName: string, value: number) => void;
}

const PriceItem: React.FC<PriceItemProps> = ({
  title,
  fieldName,
  onValueChange,
}) => {
  const [field, meta, helpers] = useField<number | "">(fieldName);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.valueAsNumber;

    // Check if the number is NaN and replace with 0 if it is
    helpers.setValue(isNaN(value) ? "" : value);

    helpers.setValue(value); // Update Formik's field value
    onValueChange(fieldName, value);
  };

  // Function to increase the count
  const increment = () => {
    const currentValue = field.value || 0;
    onValueChange(fieldName, currentValue + 1);
  };

  const decrement = () => {
    const currentValue = field.value || 0;
    if (currentValue > 0) {
      onValueChange(fieldName, currentValue - 1);
    }
  };

  return (
    <div className="h-14 flex justify-between items-center md:text-lg text-sm">
      <div className="w-1/2">
        <p>{title}</p>
      </div>
      <div className="w-1/2 flex justify-between items-center border-b border-black">
        <input
          type="number"
          {...field}
          className="text-start w-full outline-none appearance-none"
          min="0"
          onChange={handleChange}
          // Custom CSS to hide default browser spinners
          style={{
            MozAppearance: "textfield", // For Firefox
          }}
          placeholder="0"
        />
        <div className="flex flex-row gap-2">
          <p>(jours)</p>
          <div className="flex items-center flex-col ">
            <button
              type="button"
              onClick={increment}
              aria-label="Increase value"
              className="cursor-pointer outline-none"
            >
              <ChevronUpIcon />
            </button>
            <button
              type="button"
              onClick={decrement}
              aria-label="Decrease value"
              className="cursor-pointer outline-none"
            >
              <ChevronDownIcon />
            </button>
          </div>
        </div>

        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default React.memo(Pricing);
