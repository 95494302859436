import React from "react";
interface AccordionBodyProps {
  isOpen: boolean;
  children: React.ReactNode;
}

const AccordionBody: React.FC<AccordionBodyProps> = ({ isOpen, children }) => (
  <div
    className={`transition-max-height duration-700 ease-in-out md:text-lg text-sm  ${
      isOpen ? "" : "max-h-0"
    } overflow-hidden`}
  >
    <div className="pl-12 pb-8">{children}</div>
  </div>
);

export default AccordionBody;
