import React from "react";

const ConclusionDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        Notre initiative d'automatisation financière a transformé le paysage du
        contrôle de gestion pour les grandes entreprises au Cameroun. En
        intégrant des technologies avant-gardistes telles que le RPA dans les
        processus financiers, nous avons ouvert la voie à une gestion plus
        efficace, transparente, et conforme, redéfinissant ainsi l'excellence
        opérationnelle dans le domaine financier.
      </p>
    </div>
  );
};

export default ConclusionDetails;
