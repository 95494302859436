import React, { FC } from "react";

import { Outlet } from "react-router-dom";
import MainLayout from "../pages/layouts/MainLayout";

const PublicRoutes: FC = () => {
  return <MainLayout content={<Outlet />} />;
};

export default PublicRoutes;
