import React from "react";

const ConclusionDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        La réinvention digitale entreprise en réponse à une crise sans précédent
        a illustré notre capacité à nous adapter. Et à transformer les obstacles
        en tremplins pour l'innovation et la croissance. À travers ce
        partenariat, nous avons redéfini ce que signifie être un prestataire de
        services postaux dans le monde moderne, marquant ainsi le début d'une
        nouvelle ère pour la livraison de courrier en Océanie.
      </p>
    </div>
  );
};

export default ConclusionDetails;
