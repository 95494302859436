import React from "react";
import { Badge } from "../../../shared-components/Badge";
import { Button } from "../../../shared-components/Button";
import ArrowRightIcon from "../../../shared-components/icon/ArrowRight";

interface CaseStudyItemProps {
  title: string;
  description: string;
  imageUrl: string;
  link: string;
  badge: string;
}

const CaseStudyItem: React.FC<CaseStudyItemProps> = ({
  title,
  description,
  imageUrl,
  link,
  badge,
}) => {
  return (
    <div className="grid md:grid-cols-12">
      <div className="col-span-5 md:flex   flex-col justify-center gap-7 hidden">
        <div>
          <Badge description={badge} />
        </div>
        <h3 className="font-semibold text-4xl">{title}</h3>
        <p className="md:text-lg text-sm">{description}</p>
        <div>
          <Button
            description="Voir Plus"
            link={link}
            icon={<ArrowRightIcon />}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 md:hidden order-2 mt-5 ">
        <h3 className="font-semibold text-4xl">{title}</h3>

        <div className="flex justify-between flex-col items-start gap-4 md:flex-row ">
          <Badge description="Telecommunications" />

          <Button
            description="Voir Plus"
            icon={<ArrowRightIcon />}
            link={link}
          />
        </div>
      </div>
      <div className="col-span-7 order-1">
        <img
          src={imageUrl}
          alt="Antene"
          className="max-w-full max-h-full mx-auto md:w-[721px] md:h-[500px] "
        />
      </div>
    </div>
  );
};

export default CaseStudyItem;
