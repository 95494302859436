import React, { useState, useEffect } from "react";
import ArrowRightIcon from "./icon/ArrowRight";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 20) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // Ensure it returns null when not visible rather than false
  if (!isVisible) {
    return null;
  }

  return (
    <button
      onClick={scrollToTop}
      className="fixed bottom-5 right-5 z-50 p-4 bg-black text-white text-lg rounded-full shadow cursor-pointer  transition-colors"
      title="Go to top"
    >
      <div className="-rotate-90">
        <ArrowRightIcon />
      </div>
    </button>
  );
};

export default ScrollToTopButton;
