/* eslint-disable react/prop-types */
import React from "react";

interface IconProps {
  IconClass?: string;
}

export const LogoMobileIcon: React.FC<IconProps> = ({ IconClass }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="132"
    height="28"
    viewBox="0 0 132 28"
    fill="none"
  >
    <mask
      id="mask0_766_1437"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="132"
      height="28"
    >
      <path d="M131.333 0H0V28H131.333V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_766_1437)">
      <path
        d="M51.4682 10.1653C50.4026 10.1653 49.6186 10.5824 49.1325 11.2773C48.6934 10.5666 47.9726 10.1653 47.0012 10.1653C46.0297 10.1653 45.2618 10.5517 44.8228 11.2001V10.3809H42.8008V18.105H44.8228V13.7641C44.8228 12.6057 45.4342 12.0186 46.2963 12.0186C47.1584 12.0186 47.6126 12.575 47.6126 13.4863V18.105H49.6346V13.7641C49.6346 12.6057 50.199 12.0186 51.0922 12.0186C51.9384 12.0186 52.4086 12.575 52.4086 13.4863V18.1058H54.4306V13.3478C54.4306 11.4165 53.2547 10.1653 51.4682 10.1653Z"
        fill="currentColor"
      />
      <path
        d="M60.6081 10.1654C59.4642 10.1654 58.6332 10.5826 58.0689 11.2932V7.29224H56.0469V18.106H58.0689V17.1947C58.6332 17.9053 59.4642 18.3224 60.6081 18.3224C62.7083 18.3224 64.4325 16.5462 64.4325 14.2444C64.4325 11.9424 62.7083 10.1663 60.6081 10.1663V10.1654ZM60.2321 16.4218C58.9941 16.4218 58.0689 15.5412 58.0689 14.2435C58.0689 12.9458 58.9941 12.0652 60.2321 12.0652C61.4702 12.0652 62.4105 12.9458 62.4105 14.2435C62.4105 15.5412 61.4862 16.4218 60.2321 16.4218Z"
        fill="currentColor"
      />
      <path
        d="M69.818 10.1653C67.514 10.1653 65.6797 11.9423 65.6797 14.2433C65.6797 16.5444 67.5134 18.3214 69.818 18.3214C72.1227 18.3214 73.972 16.5453 73.972 14.2433C73.972 11.9415 72.1227 10.1653 69.818 10.1653ZM69.818 16.3753C68.6267 16.3753 67.7014 15.4946 67.7014 14.2433C67.7014 12.9921 68.626 12.1115 69.818 12.1115C71.0094 12.1115 71.9494 12.9921 71.9494 14.2433C71.9494 15.4946 71.024 16.3753 69.818 16.3753Z"
        fill="currentColor"
      />
      <path
        d="M81.586 11.293C81.022 10.5982 80.1753 10.1653 79.0307 10.1653C76.946 10.1653 75.2227 11.9423 75.2227 14.2433C75.2227 16.5444 76.9467 18.3214 79.0307 18.3214C80.1747 18.3214 81.022 17.8894 81.586 17.1937V18.105H83.608V10.3818H81.586V11.293ZM79.4073 16.4217C78.1693 16.4217 77.244 15.5411 77.244 14.2433C77.244 12.9456 78.1687 12.065 79.4073 12.065C80.6467 12.065 81.586 12.9456 81.586 14.2433C81.586 15.5411 80.6607 16.4217 79.4073 16.4217Z"
        fill="currentColor"
      />
      <path
        d="M94.2796 11.7103C93.6529 10.7683 92.6496 10.1812 91.3169 10.1812C89.1389 10.1812 87.3516 11.9582 87.3516 14.2444C87.3516 16.5305 89.1382 18.3075 91.3169 18.3075C92.6496 18.3075 93.6529 17.7204 94.2796 16.7776V18.106H95.6429V7.29224H94.2796V11.7103ZM91.4896 17.009C89.9216 17.009 88.7156 15.819 88.7156 14.2435C88.7156 12.668 89.9229 11.4781 91.4896 11.4781C93.0569 11.4781 94.2796 12.6672 94.2796 14.2435C94.2796 15.8198 93.0722 17.009 91.4896 17.009Z"
        fill="currentColor"
      />
      <path
        d="M97.5215 10.3818H98.8848V18.106H97.5215V10.3818Z"
        fill="currentColor"
      />
      <path
        d="M98.2098 7.1687C97.6925 7.1687 97.2852 7.58577 97.2852 8.08003C97.2852 8.57423 97.6925 8.97557 98.2098 8.97557C98.7272 8.97557 99.1192 8.57423 99.1192 8.08003C99.1192 7.58577 98.7118 7.1687 98.2098 7.1687Z"
        fill="currentColor"
      />
      <path
        d="M107.536 11.71C106.91 10.768 105.906 10.1809 104.59 10.1809C102.365 10.1809 100.594 11.9579 100.594 14.1976C100.594 16.4373 102.365 18.2292 104.59 18.2292C105.906 18.2292 106.91 17.6264 107.536 16.6844V17.7814C107.536 19.2334 106.549 20.1455 104.919 20.1455C103.384 20.1455 102.725 19.5742 102.364 18.8478L101.173 19.512C101.816 20.7326 103.133 21.3968 104.888 21.3968C106.925 21.3968 108.885 20.192 108.885 17.7814V10.3816H107.536V11.71ZM104.747 16.9472C103.164 16.9472 101.957 15.7574 101.957 14.1976C101.957 12.6378 103.164 11.4628 104.747 11.4628C106.33 11.4628 107.536 12.6528 107.536 14.1976C107.536 15.7424 106.33 16.9472 104.747 16.9472Z"
        fill="currentColor"
      />
      <path
        d="M111.452 7.1687C110.935 7.1687 110.527 7.58577 110.527 8.08003C110.527 8.57423 110.935 8.97557 111.452 8.97557C111.969 8.97557 112.361 8.57423 112.361 8.08003C112.361 7.58577 111.953 7.1687 111.452 7.1687Z"
        fill="currentColor"
      />
      <path
        d="M110.762 10.3818H112.125V18.106H110.762V10.3818Z"
        fill="currentColor"
      />
      <path
        d="M116.702 8.21899L115.338 8.62033V10.3816H113.787V11.6793H115.338V15.866C115.338 17.7972 116.436 18.415 118.787 18.1057V16.9009C117.392 16.9622 116.702 17.0401 116.702 15.866V11.6793H118.787V10.3816H116.702V8.21899Z"
        fill="currentColor"
      />
      <path
        d="M126.727 11.71C126.1 10.768 125.097 10.1809 123.765 10.1809C121.586 10.1809 119.799 11.9579 119.799 14.244C119.799 16.5302 121.586 18.3072 123.765 18.3072C125.097 18.3072 126.1 17.7201 126.727 16.7772V18.1056H128.09V10.3816H126.727V11.71ZM123.937 17.0086C122.369 17.0086 121.163 15.8187 121.163 14.2432C121.163 12.6677 122.37 11.4778 123.937 11.4778C125.504 11.4778 126.727 12.6668 126.727 14.2432C126.727 15.8195 125.52 17.0086 123.937 17.0086Z"
        fill="currentColor"
      />
      <path
        d="M129.971 6.82861H131.334V18.1059H129.971V6.82861Z"
        fill="currentColor"
      />
      <path d="M0 25.8923H38.2487V28.0002H0V25.8923Z" fill="currentColor" />
      <path d="M0 0H38.2487V2.10786H0V0Z" fill="currentColor" />
      <path
        d="M23.9212 11.7257C23.9212 9.12867 21.7781 7.01587 19.1438 7.01587C16.5095 7.01587 14.3672 9.12867 14.3672 11.7257C14.3672 14.3229 16.5103 16.4349 19.1438 16.4349C21.7773 16.4349 23.9203 14.322 23.9203 11.7257H23.9212ZM16.4389 11.7257C16.4389 10.2547 17.6525 9.0582 19.1446 9.0582C20.6367 9.0582 21.8504 10.2547 21.8504 11.7257C21.8504 13.1968 20.6367 14.3933 19.1446 14.3933C17.6525 14.3933 16.4389 13.1968 16.4389 11.7257Z"
        fill="currentColor"
      />
      <path
        d="M12.0455 14.148C11.778 14.148 11.5257 14.2508 11.3364 14.4374L4.03489 21.6325C3.64462 22.0181 3.64462 22.6441 4.03489 23.0297C4.22413 23.2163 4.47562 23.3191 4.74393 23.3191C5.01223 23.3191 5.26288 23.2163 5.45213 23.0297L12.0455 16.532C13.8336 18.2949 18.3957 22.7934 18.4327 22.8299L18.5269 22.737L18.4377 22.8357C18.4377 22.8357 18.4461 22.8439 18.4512 22.8481C18.5017 22.8945 18.6884 23.0504 18.9635 23.1077C19.0324 23.1226 19.1014 23.13 19.1687 23.13C19.4218 23.13 19.654 23.0264 19.8609 22.8232C19.9652 22.7212 24.2833 18.4641 26.2431 16.5311L32.8373 23.0289C33.0265 23.2155 33.278 23.3183 33.5463 23.3183C33.8146 23.3183 34.0661 23.2155 34.2545 23.0289C34.4437 22.8423 34.5481 22.5943 34.5481 22.3307C34.5481 22.067 34.4437 21.8191 34.2545 21.6317L26.953 14.4366C26.7637 14.25 26.5123 14.1472 26.2406 14.1472C25.9689 14.1472 25.7216 14.25 25.5324 14.4366L19.1434 20.7353L12.7545 14.4366C12.5653 14.25 12.3137 14.1472 12.0446 14.1472L12.0455 14.148Z"
        fill="currentColor"
      />
      <path
        d="M11.8468 10.7316H8.59853L3.04476 5.25961C2.85552 5.07304 2.60403 4.97021 2.33572 4.97021C2.06742 4.97021 1.81677 5.07304 1.62752 5.25961C1.43828 5.44619 1.33398 5.69412 1.33398 5.95781C1.33398 6.2215 1.43828 6.47026 1.62752 6.65683L7.7288 12.6695L7.76753 12.7076H11.846C12.4263 12.7076 12.899 12.264 12.899 11.7192C12.899 11.1744 12.4263 10.7316 11.846 10.7316H11.8468Z"
        fill="currentColor"
      />
      <path
        d="M26.4274 12.7077H30.5337L36.6745 6.6561C36.8637 6.46953 36.968 6.2216 36.968 5.95708C36.968 5.69256 36.8637 5.44545 36.6745 5.25888C36.4852 5.07231 36.2337 4.96948 35.9663 4.96948C35.6988 4.96948 35.4473 5.07231 35.2572 5.25888L29.7043 10.7308H26.4274C25.8513 10.7308 25.3828 11.1736 25.3828 11.7184C25.3828 12.2632 25.8513 12.7069 26.4274 12.7069V12.7077Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default LogoMobileIcon;
