import React from "react";
import { useField } from "formik";

interface TextAreaFieldProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  name: string; // Important for Formik to manage data
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props.name);

  return (
    <div className="relative md:text-lg text-sm">
      <textarea
        {...field}
        {...props}
        className={`peer bg-transparent border-b border-black text-black focus:outline-none focus:border-black placeholder-transparent w-full h-auto ${
          meta.touched && meta.error ? "border-red-500" : ""
        }`}
        placeholder={props.placeholder}
        aria-invalid={meta.error && meta.touched ? "true" : "false"}
        aria-describedby={`${props.id || props.name}-error`}
      />
      <label
        htmlFor={props.id || props.name}
        className={`absolute left-0 transition-all text-black peer-focus:-top-3.5 peer-focus:text-black ${
          field.value ? "-top-3.5 text-black" : "top-4 text-base"
        }`}
      >
        {label}
      </label>
      {meta.touched && meta.error && (
        <div
          id={`${props.id || props.name}-error`}
          className="text-red-500 text-xs"
        >
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default TextAreaField;
