/* eslint-disable react/prop-types */
import React from "react";

interface IconProps {
  IconClass?: string;
}

export const ChevronUpIcon: React.FC<IconProps> = ({ IconClass }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4.14708 12.3541C3.95147 12.1592 3.9509 11.8426 4.14582 11.647L9.6108 6.16252C9.82574 5.9468 10.1751 5.9468 10.39 6.16252L15.855 11.647C16.0499 11.8426 16.0493 12.1592 15.8537 12.3541C15.6581 12.549 15.3415 12.5485 15.1466 12.3528L10.0004 7.18827L4.85418 12.3528C4.65927 12.5485 4.34269 12.549 4.14708 12.3541Z"
      fill="black"
    />
  </svg>
);

export default ChevronUpIcon;
