import React from "react";
import SectionContainer from "../../../shared-components/SectionContainer";
import { staffMembers } from "./StaffData";
import StaffMember from "./StaffMenber";
import TitlePage from "../../../shared-components/TitlePage";
const Teams = () => {
  return (
    <SectionContainer>
      <div className="md:pb-32 pb-16">
        <TitlePage>Équipe</TitlePage>
        <div className="grid lg:grid-cols-2  lg:gap-x-5 lg:gap-y-24 gap-y-12 mt-12 ">
          {staffMembers.map((member, index) => (
            <StaffMember
              key={index}
              imageMobUrl={member.imageMobUrl}
              name={member.name}
              title={member.title}
              description={member.description}
              imageUrl={member.imageUrl}
              link={member.link}
              country={member.county}
            />
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};
export default React.memo(Teams);
