import React from "react";

type GridContainerProps = {
  firstChildren?: React.ReactNode;
  secondChildren?: React.ReactNode;
  title: string;
  className?: string;
};

const GridContainer: React.FC<GridContainerProps> = ({
  firstChildren,
  secondChildren,
  className,
  title,
}) => {
  return (
    <div className={`grid md:grid-cols-12 gap-5 ${className} `}>
      <div className={`md:col-span-3 md:mb-4 `}>
        <h5 className="md:text-[32px] text-[24px] md:leading-[40px] ">
          {title}
        </h5>{" "}
        {firstChildren}
      </div>
      <div className={`md:col-span-9 mt-2`}> {secondChildren}</div>
    </div>
  );
};

export default GridContainer;
