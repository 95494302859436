import React from "react";

const ApproachDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        Nous avons conçu et déployé un système de gestion de RMAs sur mesure,
        enraciné dans une approche centrée sur l'utilisateur. Notre solution
        englobe :
      </p>

      <div>
        <p className="font-bold">Portail Web Intuitif</p>
        <p>
          Un portail accessible aux techniciens SAV pour initier des demandes de
          retour avec facilité et efficacité, améliorant ainsi l'expérience
          utilisateur et accélérant le processus de retour.
        </p>
      </div>
      <div>
        <p className="font-bold">Intégration avec les Transporteurs</p>
        <p>
          Une collaboration fluide avec les services de transport pour générer
          automatiquement des étiquettes de retour, simplifiant la logistique du
          retour de marchandises.
        </p>
      </div>
      <div>
        <p className="font-bold">Connecteurs d'Intégration</p>
        <p>
          Six interfaces d'intégration essentielles pour assimiler les RMAs
          issus d'autres systèmes, offrant une vue unifiée et complète des
          opérations de retour.
        </p>
      </div>
      <div>
        <p className="font-bold">Suivi en Temps Réel</p>
        <p>
          Capacité à suivre en temps réel toutes les étapes du processus de
          retour, de la réception à la réexpédition post-réparation,
          garantissant une transparence totale.
        </p>
      </div>
      <div>
        <p className="font-bold">Interface de Reporting Avancé</p>
        <p>
          Une fonctionnalité de reporting développée pour s'intégrer à l'outil
          BI du client, permettant une analyse approfondie et une prise de
          décision éclairée sur la gestion des retours.
        </p>
      </div>
    </div>
  );
};

export default ApproachDetails;
