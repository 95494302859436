import React from "react";

import SectionContainer from "../../shared-components/SectionContainer";

import HeaderText from "../../shared-components/HeaderText";

const LegalMention = () => {
  return (
    <SectionContainer>
      <div className="">
        <div className="grid  mb-32  ">
          <div className="flex flex-col gap-20 ">
            <HeaderText>Mentions légales</HeaderText>
            <p>
              Ce site web est la propriété{" "}
              <span className="font-bold">
                {" "}
                MBOA DIGITAL, Société par Actions Simplifiée
              </span>{" "}
              , au capital de
              <span className="font-bold"> 1000€</span>
              <p>
                Inscrite au régistre de commerce de Paris sous le numéro{" "}
                <span className="font-bold"> B 839 651 411</span>
              </p>
            </p>
            <div>
              <p>
                {" "}
                <span className="font-bold"> SIREN 839651411</span>
              </p>
              <p>
                Numéro de TVA Intracommunautaire{" "}
                <span className="font-bold"> FR45839651411</span>
              </p>
            </div>
            <div className="grid gap-2">
              <p>Dont le siège social est situé au</p>
              <ul className="grid gap-2 ">
                <li>66 Avenue des Champs-Elysées</li>
                <li>75008 Paris, France</li>
                <li className="flex gap-4">
                  {" "}
                  Email :{" "}
                  <a
                    href="mailto:contact@mboadigital.tech"
                    className="outline-none hover:underline"
                  >
                    contact@mboadigital.tech
                  </a>{" "}
                </li>
              </ul>
            </div>

            <div className="grid gap-2 grid-cols-2">
              <div>
                <p className="md:text-[32px] text-[24px] leading-[40px] ">
                  Responsable de la publication
                </p>
                <div>
                  <p className="font-bold mt-4">Habib Tafo</p>
                  <p className="">
                    En sa qualité de{" "}
                    <span className="font-semibold">Directeur Général</span>{" "}
                  </p>
                </div>
              </div>
              <div>
                <p className="md:text-[32px] text-[24px] leading-[40px] ">
                  Hébergement
                </p>
                <div>
                  <p className="font-bold mt-4">
                    Amazon Web Services EMEA SARL
                  </p>
                  <p className="">
                    Adresse : 38 Avenue John F. Kennedy, L-1855, Luxembourg
                  </p>
                </div>
              </div>
            </div>
            <div className="grid gap-2 grid-cols-2">
              <div>
                <p className="md:text-[32px] text-[24px] leading-[40px] ">
                  Développement
                </p>
                <div>
                  <p className="font-bold mt-4">MBOA DIGITAL</p>
                  <p className="">
                    <a href="http://" className="outline-none hover:underline ">
                      www.mboadigital.tech
                    </a>{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="grid gap-2 ">
              <div>
                <p className="md:text-[32px] text-[24px] leading-[40px] ">
                  Propriété intellectuelle
                </p>
                <div>
                  <p className="mt-4">
                    Le contenu de ce site web, comprenant, de manière non
                    exhaustive, les graphiques, images, textes, vidéos,
                    animations, sons, logos, gifs et icônes ainsi que leur mise
                    en forme sont la propriété exclusive de la société MBOA
                    DIGITAL à l'exception des marques, logos ou contenus
                    appartenant à d'autres sociétés partenaires ou auteurs.
                  </p>
                  <p className="mt-4">
                    Toute reproduction, distribution, modification, adaptation,
                    retransmission ou publication, même partielle, de ces
                    différents éléments est strictement interdite sans l'accord
                    exprès par écrit de MBOA DIGITAL. Cette représentation ou
                    reproduction, par quelque procédé que ce soit, constitue une
                    contrefaçon sanctionnée par les articles L.3335-2 et
                    suivants du Code de la propriété intellectuelle. Le
                    non-respect de cette interdiction constitue une contrefaçon
                    pouvant engager la responsabilité civile et pénale du
                    contrefacteur.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid gap-2 ">
              <div>
                <p className="md:text-[32px] text-[24px] leading-[40px] ">
                  Données personnelles
                </p>
                <div>
                  <p className="mt-4">
                    Conformément à la loi "Informatique et Libertés" du 6
                    janvier 1978 modifiée, vous bénéficiez d’un droit d’accès,
                    de rectification et d’opposition aux informations qui vous
                    concernent, que vous pouvez exercer en vous adressant à
                    contact@mboadigital.tech. Pour plus d'informations
                    concernant le traitement de vos données personnelles,
                    veuillez consulter notre Politique de confidentialité
                    accessible sur le site.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid gap-2 ">
              <div>
                <p className="md:text-[32px] text-[24px] leading-[40px] ">
                  Cookies
                </p>
                <div>
                  <p className="mt-4">
                    Le site{" "}
                    <a href="http://" className="outline-none hover:underline ">
                      www.mboadigital.tech
                    </a>{" "}
                    peut-être amené à vous demander l'acceptation des cookies
                    pour des besoins de statistiques et d'affichage. Un cookie
                    est une information déposée sur votre disque dur par le
                    serveur du site que vous visitez. Il contient plusieurs
                    données qui sont stockées sur votre ordinateur dans un
                    simple fichier texte auquel un serveur accède pour lire et
                    enregistrer des informations. Certaines parties de ce site
                    ne peuvent être fonctionnelles sans l’acceptation de
                    cookies.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid gap-2 ">
              <div>
                <p className="md:text-[32px] text-[24px] leading-[40px] ">
                  Droit applicable
                </p>
                <div>
                  <p className="mt-4">
                    En cas de litige relatif à l’utilisation de ce site web, le
                    droit français fera foi.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default React.memo(LegalMention);
