import React, { useState } from "react";
import AccordionHeader from "./AccordionHeader";
import AccordionBody from "./AccordionBody";
interface AccordionProps {
  title: string;
  id: number;
  children: React.ReactNode;
  isOpen: boolean;
  toggleOpen: (index: number) => void;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  children,
  isOpen,
  toggleOpen,
  id,
}) => {
  return (
    <div className="overflow-hidden border-b border-b-neutral-90">
      <AccordionHeader
        title={title}
        toggleOpen={() => toggleOpen(id)}
        isOpen={isOpen}
      />
      <AccordionBody isOpen={isOpen}>{children}</AccordionBody>
    </div>
  );
};

export default Accordion;
