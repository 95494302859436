import { Link } from "react-router-dom";
import { Button } from "./Button";
import ArrowUpRightIcon from "./icon/ArrowUpRight";
import LogoIcon from "./icon/Logo";

export const Footer = () => {
  return (
    <footer className="bg-black  text-white w-full">
      <div className="mx-auto p-4 max-w-[1400px]  py-16 px-4  md:px-16 lg:px-32  xxl:px-4  flex flex-col md:gap-16  gap-4">
        <div className="grid md:grid-cols-2 ">
          <div className="grid md:grid-cols-2  md:gap-16 gap-4">
            <div className="flex flex-col md:gap-6 gap-2">
              <h4 className="text-2xl ">France</h4>
              <div>
                <p className="text-sm text-neutral-50">
                  66 Avenue des Champs-Élysées
                </p>
                <p className="text-sm text-neutral-50">75008 Paris</p>
                <p className="text-sm text-neutral-50">
                  Tel: +33 1 88 61 02 02{" "}
                </p>
              </div>
            </div>
            <div className="flex flex-col md:gap-6 gap-2">
              <h4 className="text-2xl ">Cameroun</h4>
              <div>
                <p className="text-sm text-neutral-50">
                  Sis face immeuble Orange Bastos - Yaoundé
                </p>
                <p className="text-sm text-neutral-50">BP 14757 Yaoundé</p>
                <p className="text-sm text-neutral-50">
                  Tel: +237 6 70 15 88 89
                </p>
              </div>
            </div>
          </div>
          <div className="flex items-end justify-end mt-6 md:mt-0 ">
            <Button
              variant="white"
              description="LinkedIn"
              icon={<ArrowUpRightIcon />}
              link="https://www.linkedin.com/company/mboadigital/"
              target="_blank"
            />
          </div>
        </div>
        <div className="flex md:flex-row gap-4 flex-col justify-between items-center">
          <div className="flex  justify-between md:justify-start items-center order-2 md:order-1 gap-8 w-full md:w-auto">
            <div className="text-white">
              <LogoIcon />
            </div>
            <p className="text-white/40 md:text-lg text-sm">
              © 2024 MBOA DIGITAL
            </p>
          </div>
          <div className="flex flex-col md:flex-row justify-end md:gap-8 gap-2 items-end order-1  text-sm text-white w-full md:w-auto">
            <Link
              className="cursor-pointer"
              to="https://mboadigital.zohorecruit.eu/jobs/Careers"
              target="_blank"
            >
              Nous rejoindre
            </Link>
            <Link className="cursor-pointer" to="/contact-us">
              Contact
            </Link>
            <Link className="cursor-pointer" to="/legal-mention">
              Mentions légales
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};
