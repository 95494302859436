import React, { useEffect, useState } from "react";
import SectionContainer from "../../../shared-components/SectionContainer";
import HeaderText from "../../../shared-components/HeaderText";
import ApproachDetails from "./components/ApproachDetails";
import GridContainer from "../../../shared-components/GridContainer";
import { Badge } from "../../../shared-components/Badge";
import { carouselDate } from "../../../data/data";
import IndustryCarousel from "../../../shared-components/IndustryCarousel";
import { Button } from "../../../shared-components/Button";
import ArrowBackIcon from "../../../shared-components/icon/ArrowBack";
import { config } from "react-spring";

import Carousel from "react-spring-3d-carousel";

const DigiPediaMed = () => {
  let index = 1; // Initialize index for accessing ZindexTable

  let cards = [
    {
      key: 1,
      content: (
        <img
          src="/assets/images/digiped/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 2,
      content: (
        <img
          src="/assets/images/digiped/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 3,
      content: (
        <img
          src="/assets/images/digiped/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 4,
      content: (
        <img
          src="/assets/images/digiped/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 5,
      content: (
        <img
          src="/assets/images/digiped/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 6,
      content: (
        <img
          src="/assets/images/digiped/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
  ];

  const [goToSlide, setGoToSlide] = useState(0);
  const slides = cards.map((card, index) => {
    return {
      key: card.key,
      content: (
        <div
          style={{
            transform: goToSlide === index ? "scale(1.2)" : "scale(0.8)",
            transition: "transform 0.5s ease",
          }}
        >
          {card.content}
        </div>
      ),
      onClick: () => setGoToSlide(index),
      config: {
        scale: goToSlide === index ? 1.6 : 1,
      },
    };
  });

  useEffect(() => {
    // Set up an interval to update the z-index periodically
    const interval1 = setInterval(() => {
      setGoToSlide(index);
      index = (index + 1) % cards.length;
    }, 1875); // Interval set to 3 seconds

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(interval1);
  }, []);
  return (
    <div className="w-full flex flex-col md:gap-32 gap-16 md:pb-32 pb-16 ">
      <SectionContainer>
        <div className="grid md:grid-cols-12">
          <div className="flex flex-col gap-8 col-span-8">
            <div>
              <Button link="/case-studies" icon={<ArrowBackIcon />} />
            </div>

            <div>
              <Badge description="Santé" />
            </div>

            <HeaderText>
              Révolution Numérique dans le Secteur de la Santé Pédiatrique
            </HeaderText>
          </div>
        </div>
        <div className="grid md:grid-cols-12  mt-12">
          <div className="md:col-span-4"></div>
          <div className="md:col-span-8">
            <p className="font-bold mb-6">Contexte</p>
            <p>
              Un prestigieux centre de santé pour enfants, reconnu pour son
              excellence en matière de services médicaux, chirurgicaux,
              cardiaques et de santé mentale complexes, fait face à un défi
              majeur : diffuser et rendre accessibles ses recommandations
              cliniques de pointe à des milliers de parents et professionnels de
              la santé à travers la région Pacifique. En tant que centre
              hospitalier de référence, l'organisation aspire également à
              devenir le leader national en matière de formation en pédiatrie.
            </p>
          </div>
        </div>
      </SectionContainer>

      <SectionContainer>
        <img
          src="/assets/images/digiPediaMed.svg"
          alt="antene"
          className="max-w-full w-full h-auto"
        />
      </SectionContainer>

      <SectionContainer>
        <GridContainer
          title="Approche"
          secondChildren={
            <p>
              Nous avons été approché par ce centre pour notre historique de
              fournisseur de services informatiques de qualité pour une autre
              entreprise du Pacifique. Leur principale préoccupation était
              d’avoir une base documentaire accessible pour les lecteurs et
              hautement disponible pour une utilisation à grande échelle.
            </p>
          }
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer title="Solution" secondChildren={<ApproachDetails />} />
      </SectionContainer>
      <SectionContainer>
        <div className="h-[400px] md:block hidden ">
          <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={2}
            showNavigation={false}
            animationConfig={config.gentle}
          />
        </div>

        <div className="flex flex-col md:gap-12 gap-4 md:hidden">
          <img
            src="/assets/images/digiped/img1.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/digiped/img2.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
        </div>
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Résultats"
          secondChildren={
            <p>
              Le site web a transformé la manière dont les recommandations
              cliniques et les directives sont partagées et consultées par les
              professionnels de la santé, rendant les dernières avancées en
              pédiatrie accessibles à un public plus large. Les praticiens
              disposent désormais d'outils précis pour le calcul des dosages,
              améliorant significativement la sécurité et l'efficacité des
              traitements pédiatriques. Son usage s’est avéré précieux pendant
              l'épidémie de Covid-19. Les protocoles ayant fait consensus y ont
              été ajoutés, pour exploitation par des centaines de médecins du
              Pacifique.
            </p>
          }
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Conclusion"
          secondChildren={
            <p>
              Notre intervention a marqué un tournant dans la diffusion des
              connaissances en santé pédiatrique, établissant un nouveau
              standard en matière de communication médicale et de soutien aux
              professionnels de la santé. Grâce à une approche innovante et une
              collaboration étroite avec le centre de santé, nous avons
              contribué à améliorer la qualité des soins dispensés aux enfants
              et aux jeunes de la région, renforçant ainsi la mission éducative
              et de recherche du centre. Aujourd'hui, nous travaillons sur une
              déclinaison en application mobile du site, pour aller plus loin
              dans la sensibilisation et la prévention des accidents domestiques
              impliquant des enfants.
            </p>
          }
        />
      </SectionContainer>

      <SectionContainer>
        <div className="grid md:grid-cols-12 mb-12">
          <div className="md:col-span-7  grid md:gap-12 gap-9">
            <h5 className="text-[32px] leading-[40px] ">Travaux connexes</h5>
          </div>
        </div>
        <IndustryCarousel items={carouselDate} />
      </SectionContainer>
    </div>
  );
};

export default React.memo(DigiPediaMed);
