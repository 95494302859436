import React, { RefObject } from "react";

type SectionContainerProps = {
  bgColor?: string;
  children: React.ReactNode;
  myRef?: RefObject<HTMLDivElement>;
};

const SectionContainer: React.FC<SectionContainerProps> = ({
  bgColor = "bg-white",
  children,
  myRef,
}) => {
  // Use a Tailwind utility class for white and conditionally add custom background colors

  return (
    <div className={`${bgColor} text-black w-full relative `} ref={myRef}>
      <div className="mx-auto px-4   lg:px-32  xxl:px-4  max-w-[1400px]  ">
        {children}
      </div>
    </div>
  );
};

export default SectionContainer;
