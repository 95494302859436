/* eslint-disable react/prop-types */
import React from "react";

interface IconProps {
  IconClass?: string;
}

export const LogoIcon: React.FC<IconProps> = ({ IconClass }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="164"
    height="35"
    viewBox="0 0 164 35"
    fill="none"
  >
    <path
      d="M64.2706 12.707C62.9399 12.707 61.961 13.2284 61.3539 14.097C60.8057 13.2087 59.9056 12.707 58.6925 12.707C57.4794 12.707 56.5204 13.19 55.9722 14.0006V12.9765H53.4473V22.6317H55.9722V17.2055C55.9722 15.7575 56.7358 15.0236 57.8123 15.0236C58.8889 15.0236 59.456 15.7191 59.456 16.8583V22.6317H61.9809V17.2055C61.9809 15.7575 62.6857 15.0236 63.8011 15.0236C64.8577 15.0236 65.4448 15.7191 65.4448 16.8583V22.6327H67.9698V16.6852C67.9698 14.2711 66.5014 12.707 64.2706 12.707Z"
      fill="currentColor"
    />
    <path
      d="M75.685 12.7068C74.2566 12.7068 73.2189 13.2281 72.5142 14.1164V9.11523H69.9893V22.6324H72.5142V21.4933C73.2189 22.3816 74.2566 22.903 75.685 22.903C78.3076 22.903 80.4607 20.6827 80.4607 17.8054C80.4607 14.928 78.3076 12.7078 75.685 12.7078V12.7068ZM75.2155 20.5273C73.6695 20.5273 72.5142 19.4265 72.5142 17.8043C72.5142 16.1822 73.6695 15.0814 75.2155 15.0814C76.7616 15.0814 77.9358 16.1822 77.9358 17.8043C77.9358 19.4265 76.7815 20.5273 75.2155 20.5273Z"
      fill="currentColor"
    />
    <path
      d="M87.186 12.707C84.3093 12.707 82.0186 14.9283 82.0186 17.8046C82.0186 20.6809 84.3082 22.9022 87.186 22.9022C90.0638 22.9022 92.3734 20.682 92.3734 17.8046C92.3734 14.9272 90.0638 12.707 87.186 12.707ZM87.186 20.4695C85.6988 20.4695 84.5435 19.3687 84.5435 17.8046C84.5435 16.2405 85.6977 15.1397 87.186 15.1397C88.6743 15.1397 89.8475 16.2405 89.8475 17.8046C89.8475 19.3687 88.6921 20.4695 87.186 20.4695Z"
      fill="currentColor"
    />
    <path
      d="M101.878 14.1167C101.173 13.2481 100.117 12.707 98.6874 12.707C96.0837 12.707 93.9316 14.9283 93.9316 17.8046C93.9316 20.6809 96.0848 22.9022 98.6874 22.9022C100.116 22.9022 101.173 22.3622 101.878 21.4925V22.6317H104.403V12.9776H101.878V14.1167ZM99.1579 20.5275C97.6119 20.5275 96.4566 19.4268 96.4566 17.8046C96.4566 16.1825 97.6108 15.0817 99.1579 15.0817C100.705 15.0817 101.878 16.1825 101.878 17.8046C101.878 19.4268 100.723 20.5275 99.1579 20.5275Z"
      fill="currentColor"
    />
    <path
      d="M117.731 14.6378C116.949 13.4603 115.696 12.7265 114.032 12.7265C111.312 12.7265 109.08 14.9477 109.08 17.8054C109.08 20.663 111.311 22.8843 114.032 22.8843C115.696 22.8843 116.949 22.1504 117.731 20.9719V22.6324H119.434V9.11523H117.731V14.6378ZM114.248 21.2611C112.29 21.2611 110.784 19.7737 110.784 17.8043C110.784 15.835 112.291 14.3476 114.248 14.3476C116.204 14.3476 117.731 15.8339 117.731 17.8043C117.731 19.7748 116.224 21.2611 114.248 21.2611Z"
      fill="currentColor"
    />
    <path
      d="M121.779 12.9775H123.482V22.6327H121.779V12.9775Z"
      fill="currentColor"
    />
    <path
      d="M122.641 8.96106C121.995 8.96106 121.486 9.48243 121.486 10.1002C121.486 10.718 121.995 11.2196 122.641 11.2196C123.287 11.2196 123.776 10.718 123.776 10.1002C123.776 9.48243 123.268 8.96106 122.641 8.96106Z"
      fill="currentColor"
    />
    <path
      d="M134.284 14.6381C133.502 13.4607 132.249 12.7268 130.605 12.7268C127.826 12.7268 125.614 14.9481 125.614 17.7477C125.614 20.5473 127.826 22.7872 130.605 22.7872C132.249 22.7872 133.502 22.0337 134.284 20.8562V22.2275C134.284 24.0424 133.051 25.1826 131.016 25.1826C129.098 25.1826 128.276 24.4684 127.825 23.5605L126.338 24.3907C127.14 25.9165 128.785 26.7467 130.977 26.7467C133.521 26.7467 135.968 25.2407 135.968 22.2275V12.9776H134.284V14.6381ZM130.801 21.1848C128.824 21.1848 127.317 19.6974 127.317 17.7477C127.317 15.798 128.824 14.3293 130.801 14.3293C132.777 14.3293 134.284 15.8167 134.284 17.7477C134.284 19.6787 132.777 21.1848 130.801 21.1848Z"
      fill="currentColor"
    />
    <path
      d="M139.174 8.96106C138.528 8.96106 138.02 9.48243 138.02 10.1002C138.02 10.718 138.528 11.2196 139.174 11.2196C139.82 11.2196 140.309 10.718 140.309 10.1002C140.309 9.48243 139.8 8.96106 139.174 8.96106Z"
      fill="currentColor"
    />
    <path
      d="M138.313 12.9775H140.016V22.6327H138.313V12.9775Z"
      fill="currentColor"
    />
    <path
      d="M145.73 10.2744L144.028 10.7761V12.9776H142.09V14.5998H144.028V19.8332C144.028 22.2472 145.397 23.0194 148.334 22.6328V21.1267C146.591 21.2034 145.73 21.3009 145.73 19.8332V14.5998H148.334V12.9776H145.73V10.2744Z"
      fill="currentColor"
    />
    <path
      d="M158.25 14.6381C157.467 13.4607 156.214 12.7268 154.551 12.7268C151.831 12.7268 149.599 14.9481 149.599 17.8057C149.599 20.6634 151.831 22.8847 154.551 22.8847C156.214 22.8847 157.467 22.1508 158.25 20.9723V22.6328H159.952V12.9776H158.25V14.6381ZM154.766 21.2615C152.808 21.2615 151.302 19.7741 151.302 17.8047C151.302 15.8353 152.809 14.3479 154.766 14.3479C156.723 14.3479 158.25 15.8343 158.25 17.8047C158.25 19.7751 156.743 21.2615 154.766 21.2615Z"
      fill="currentColor"
    />
    <path
      d="M162.298 8.53613H164V22.6327H162.298V8.53613Z"
      fill="currentColor"
    />
    <path d="M0 32.3652H47.7624V35.0001H0V32.3652Z" fill="currentColor" />
    <path d="M0 0H47.7624V2.63482H0V0Z" fill="currentColor" />
    <path
      d="M29.8727 14.6569C29.8727 11.4106 27.1966 8.76953 23.907 8.76953C20.6175 8.76953 17.9424 11.4106 17.9424 14.6569C17.9424 17.9033 20.6185 20.5433 23.907 20.5433C27.1955 20.5433 29.8717 17.9023 29.8717 14.6569H29.8727ZM20.5293 14.6569C20.5293 12.8182 22.0449 11.3225 23.9081 11.3225C25.7713 11.3225 27.2869 12.8182 27.2869 14.6569C27.2869 16.4957 25.7713 17.9914 23.9081 17.9914C22.0449 17.9914 20.5293 16.4957 20.5293 14.6569Z"
      fill="currentColor"
    />
    <path
      d="M15.0424 17.6846C14.7084 17.6846 14.3933 17.8132 14.157 18.0464L5.03933 27.0402C4.55199 27.5222 4.55199 28.3048 5.03933 28.7867C5.27565 29.02 5.58969 29.1485 5.92473 29.1485C6.25978 29.1485 6.57277 29.02 6.80909 28.7867L15.0424 20.6646C17.2753 22.8682 22.9721 28.4913 23.0184 28.5369L23.136 28.4209L23.0247 28.5442C23.0247 28.5442 23.0352 28.5546 23.0415 28.5597C23.1045 28.6178 23.3376 28.8127 23.6811 28.8842C23.7672 28.9028 23.8533 28.9122 23.9374 28.9122C24.2535 28.9122 24.5434 28.7826 24.8018 28.5286C24.932 28.4012 30.3242 23.0797 32.7714 20.6636L41.0058 28.7857C41.2421 29.0189 41.5561 29.1474 41.8912 29.1474C42.2262 29.1474 42.5403 29.0189 42.7755 28.7857C43.0118 28.5525 43.1421 28.2426 43.1421 27.913C43.1421 27.5833 43.0118 27.2734 42.7755 27.0392L33.6579 18.0453C33.4216 17.8121 33.1075 17.6836 32.7683 17.6836C32.429 17.6836 32.1202 17.8121 31.8839 18.0453L23.9059 25.9187L15.9278 18.0453C15.6915 17.8121 15.3774 17.6836 15.0413 17.6836L15.0424 17.6846Z"
      fill="currentColor"
    />
    <path
      d="M14.7937 13.4146H10.7374L3.80232 6.57463C3.56601 6.34142 3.25197 6.21289 2.91692 6.21289C2.58188 6.21289 2.26889 6.34142 2.03257 6.57463C1.79625 6.80785 1.66602 7.11777 1.66602 7.44738C1.66602 7.77699 1.79625 8.08795 2.03257 8.32116L9.65144 15.8369L9.69975 15.8846H14.7927C15.5174 15.8846 16.1076 15.3301 16.1076 14.6491C16.1076 13.9681 15.5174 13.4146 14.7927 13.4146H14.7937Z"
      fill="currentColor"
    />
    <path
      d="M33.0017 15.8845H38.1293L45.7975 8.32006C46.0339 8.08685 46.1641 7.77693 46.1641 7.44628C46.1641 7.11563 46.0339 6.80675 45.7975 6.57354C45.5612 6.34032 45.2472 6.21179 44.9132 6.21179C44.5792 6.21179 44.2651 6.34032 44.0278 6.57354L37.0937 13.4135H33.0017C32.2823 13.4135 31.6973 13.967 31.6973 14.648C31.6973 15.329 32.2823 15.8835 33.0017 15.8835V15.8845Z"
      fill="currentColor"
    />
  </svg>
);

export default LogoIcon;
