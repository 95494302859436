import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import LogoIcon from "./icon/Logo";
import { motion, AnimatePresence } from "framer-motion";
import LogoMobileIcon from "./icon/LogoMobile";

interface NavLinkItem {
  to: string;
  label: string;
  subLinks?: NavLinkItem[];
  extraClasses?: string;
}

const Header = (): React.ReactElement => {
  const drawerVariants = {
    closed: {
      x: "100%", // Starts off the screen to the right
      opacity: 0,
    },
    open: {
      x: 0, // Moves into view
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 120,
        damping: 25,
      },
    },
  };
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [visibleSubLink, setVisibleSubLink] = useState<string | null>(null);

  const toggleSubLinks = (linkTo: string) => {
    if (visibleSubLink === linkTo) {
      setVisibleSubLink(null); // Hide sub-links if they are already visible
    } else {
      setVisibleSubLink(linkTo); // Show sub-links for the clicked link
    }
  };
  const [dropdownOpen, setDropdownOpen] = useState<Record<string, boolean>>({});
  const navigate = useNavigate();
  let timer: ReturnType<typeof setTimeout>;

  const handleMouseEnter = (label: string) => {
    if (timer) clearTimeout(timer);
    setDropdownOpen((prev) => ({ ...prev, [label]: true }));
  };
  const isAnySubLinkActive = (subLinks?: NavLinkItem[]): boolean => {
    return subLinks
      ? subLinks.some((subLink) => pathname.includes(subLink.to))
      : false;
  };

  // Delay closing the dropdown to allow moving the cursor to the dropdown
  const handleMouseLeave = (label: string) => {
    timer = setTimeout(() => {
      setDropdownOpen((prev) => ({ ...prev, [label]: false }));
    }, 30); // Delay can be adjusted as needed
  };
  const handleClick = (path: string) => {
    navigate(path);
  };

  const navigationLinks: NavLinkItem[] = [
    { to: "/home", label: "Accueil" },
    {
      to: "#",
      label: "À propos",
      subLinks: [
        { to: "/about-us/mission", label: "Mission" },
        { to: "/about-us/teams", label: "Équipe" },
      ],
    },
    {
      to: "/pricing",
      label: "Tarification",
    },
    {
      to: "/case-studies",
      label: "Cas d'etude",
    },
    {
      to: "/contact-us",
      label: "Contact",
    },
  ];

  // Function to determine NavLink active styles
  // Function to determine NavLink active styles
  const getNavLinkClass = (isActive: boolean, isSublink: boolean = false) =>
    `text-center cursor-pointer block py-2 text-start ] md:text-sm  ${
      isActive ? "text-black font-normal" : "text-neutral-70"
    } ${isSublink ? "pl-10 text-xl" : " text-[40px] leading-[52px]"}`; // Additional padding for sublinks

  return (
    <header className="w-full  fixed top-O left-0 z-[100] bg-white ">
      <div className=" max-w-[1400px]  mx-auto  flex justify-between items-center w-full py-8 md:px-4 lg:px-32  xxl:px-4">
        <NavLink to="/home" className="text-black md:block hidden">
          <LogoIcon />
        </NavLink>
        <NavLink to="/home" className="text-black md:hidden mx-4 block">
          <LogoMobileIcon />
        </NavLink>
        <nav
          className={`${
            isMenuOpen ? "flex" : "hidden"
          } md:flex hidden items-center justify-end flex-wrap w-full md:gap-10 text-sm`}
        >
          {navigationLinks.map((link, index) => {
            const hasSubLinks = link.subLinks && link.subLinks.length > 0;
            const linkActiveClass =
              isAnySubLinkActive(link.subLinks) || pathname.includes(link.to)
                ? "text-black font-normal"
                : "text-neutral-70";
            return (
              <div
                key={index}
                className="relative"
                onMouseLeave={() => hasSubLinks && handleMouseLeave(link.label)}
              >
                {hasSubLinks ? (
                  <div onMouseEnter={() => handleMouseEnter(link.label)}>
                    <button
                      className={`${linkActiveClass} py-2 rounded focus:outline-none`}
                      onClick={() => handleClick(link.to)}
                    >
                      {link.label}
                    </button>

                    {dropdownOpen[link.label] && (
                      <div
                        className="absolute left-0 mt-1 bg-white shadow-lg rounded p-2"
                        onMouseEnter={() => handleMouseEnter(link.label)}
                      >
                        {link.subLinks?.map((subLink) => (
                          <NavLink
                            key={subLink.to}
                            to={subLink.to}
                            className="block px-4 py-2 text-sm text-neutral-70 hover:text-black  cursor-pointer"
                          >
                            {subLink.label}
                          </NavLink>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <NavLink
                    to={link.to}
                    className={({ isActive }) =>
                      `${getNavLinkClass(isActive)} ${
                        link.extraClasses || ""
                      } ${isActive} `
                    }
                  >
                    {link.label}
                  </NavLink>
                )}
              </div>
            );
          })}
        </nav>

        <button
          className={`md:hidden flex mx-4 items-center ${
            isMenuOpen ? "bg-black rounded-full justify-center " : "justify-end"
          }`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          style={{ height: "56px", width: "56px" }} // Inline styles to maintain size consistency
        >
          <svg
            fill="none"
            stroke={isMenuOpen ? "white" : "currentColor"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            className="w-6 h-6"
            viewBox="0 0 24 24"
          >
            {isMenuOpen ? (
              <path d="M6 18L18 6M6 6l12 12" /> // X-icon for closing
            ) : (
              <path d="M4 6h16M4 12h16m-7 6h7" /> // Hamburger icon
            )}
          </svg>
        </button>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.nav
              variants={drawerVariants}
              initial="closed"
              animate="open"
              exit="closed"
              className="absolute  top-[90px] bg-white w-full p-10 shadow-lg min-h-screen z-50 md:hidden "
            >
              {navigationLinks.flatMap((link, index) => {
                const linkActiveClass =
                  visibleSubLink === link.to
                    ? "text-black font-normal"
                    : "text-neutral-50";
                return link.subLinks ? (
                  [
                    <NavLink
                      key={link.to}
                      to={link.to}
                      className={`${linkActiveClass} cursor-pointer block py-2 text-start text-[40px] md:text-sm leading-[52px]`}
                      onClick={() => toggleSubLinks(link.to)}
                    >
                      {link.label}
                    </NavLink>,
                    visibleSubLink === link.to &&
                      link.subLinks.map((subLink) => (
                        <NavLink
                          key={subLink.to}
                          to={subLink.to}
                          className={({ isActive }) =>
                            getNavLinkClass(isActive, true)
                          }
                          onClick={() => {
                            setIsMenuOpen(false);
                            setVisibleSubLink(null);
                          }}
                        >
                          {subLink.label}
                        </NavLink>
                      )),
                  ]
                ) : (
                  <NavLink
                    key={link.to}
                    to={link.to}
                    className={({ isActive }) =>
                      `${getNavLinkClass(isActive)} ${
                        link.extraClasses || ""
                      } ${isActive} `
                    }
                    onClick={() => setIsMenuOpen(false)}
                  >
                    {link.label}
                  </NavLink>
                );
              })}
            </motion.nav>
          )}
        </AnimatePresence>
      </div>
    </header>
  );
};

export default Header;
