import React, { FC, ButtonHTMLAttributes, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";

const baseClass =
  "flex items-center cursor-pointer relative z-20 justify-center gap-4 text-sm bg-transparent border rounded-full transition-colors focus-visible:outline-none disabled:opacity-50 disabled:pointer-events-none px-9 py-4 ";
const variants = {
  black: "border-black text-black hover:text-white",
  white: "border-white text-white hover:text-black",
};

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  description?: string;
  isLoading?: boolean;
  variant?: "black" | "white";
  icon?: ReactElement | null;
  link?: string;
  target?: string;
}

export const Button: FC<IProps> = ({
  description,
  isLoading,
  className,
  variant = "black",
  icon,
  disabled,
  link,
  target = "_self",
  onClick,
  ...props
}): ReactElement => {
  const navigate = useNavigate();
  const buttonClass = `${baseClass} ${variants[variant]} ${className}`;
  const controls = useAnimation();

  const handleMouseEnter = () => {
    controls.start({ width: "100%", opacity: 1 });
  };

  const handleMouseLeave = () => {
    controls.start({ width: "0%", opacity: 0 });
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onClick?.(event);
    if (link && !event.defaultPrevented && !disabled) {
      if (target === "_blank" || link.startsWith("mailto:")) {
        window.open(link, target);
      } else {
        navigate(link);
      }
    }
  };

  const descriptionClass = `max-w-full overflow-hidden whitespace-nowrap text-ellipsis z-20 ${
    icon ? "max-w-[calc(100%-16px)]" : ""
  }`;
  const motionDivClass = `absolute left-0 top-0 bottom-0 h-full rounded-full z-[-1] ${
    variant === "black" ? "bg-black" : "bg-white"
  }`;

  return (
    <button
      className={buttonClass}
      disabled={disabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      {...props}
    >
      <motion.div
        className={motionDivClass}
        initial={{ width: "0%" }}
        animate={controls}
        transition={{ duration: 0.3 }}
      />
      {!isLoading && (
        <>
          {description ? (
            <span className={descriptionClass}>{description}</span>
          ) : null}
          {icon}
        </>
      )}
    </button>
  );
};
