import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay"; // Include if you are using the autoplay feature
import { Badge } from "./Badge";
import { useNavigate } from "react-router-dom";

interface CarouselItem {
  image: string;
  title: string;
  badge: string;
  link: string;
}

interface CarouselProps {
  items: CarouselItem[];
}

const IndustryCarousel: React.FC<CarouselProps> = ({ items }) => {
  const navigate = useNavigate();
  const prevRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);
  return (
    <Swiper
      // Configure Swiper to use modules
      modules={[Navigation, A11y, Autoplay]}
      navigation={{
        prevEl: prevRef.current,
        nextEl: nextRef.current,
      }}
      loop={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        1024: {
          slidesPerView: 2.2,
          spaceBetween: 50,
        },
      }}
    >
      {items.map((item, index) => (
        <SwiperSlide
          key={index}
          onClick={() => {
            const fullPath = item.link.startsWith("/")
              ? item.link
              : "/" + item.link; // This will help you see what the full path being navigated to is.
            navigate(fullPath);
          }}
          className="cursor-pointer"
        >
          <img src={item.image} alt={`Slide ${index}`} className="w-full" />
          <div>
            <h3 className="text-[32px] leading-[40px] font-semibold break-all lg:line-clamp-2 line-clamp-3 my-4 ">
              {item.title}
            </h3>
            <Badge description={item.badge} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default IndustryCarousel;
