import React from "react";

const ResultsDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <div>
        <p className="font-bold">Conformité et Transparence</p>
        <p>
          La capacité à suivre les transactions en temps réel et à les aligner
          avec les budgets prévisionnels a marqué un tournant dans la gestion
          financière, assurant une conformité sans faille.
        </p>
      </div>
      <div>
        <p className="font-bold">Efficacité Optimisée</p>
        <p>
          La réduction significative du temps consacré à la collecte et à
          l'analyse des données financières a libéré les contrôleurs de gestion
          pour se concentrer sur des tâches à plus haute valeur ajoutée.
        </p>
      </div>
      <div>
        <p className="font-bold">Prise de Décision Éclairée</p>
        <p>
          Avec des données financières consolidées et actualisées à disposition,
          les décisions stratégiques sont prises avec une confiance et une
          précision accrues.
        </p>
      </div>
    </div>
  );
};

export default ResultsDetails;
