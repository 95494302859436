/* eslint-disable react/prop-types */
import React from "react";

interface IconProps {
  IconClass?: string;
}

export const LinkedDnIcon: React.FC<IconProps> = ({ IconClass }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
  >
    <circle cx="16" cy="16.5" r="16" fill="#F1F0F4" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6559 10.2818C12.6559 11.0786 12.0492 11.7235 11.1004 11.7235C10.1885 11.7235 9.58174 11.0786 9.60052 10.2818C9.58174 9.44617 10.1885 8.82007 11.1185 8.82007C12.0492 8.82007 12.6377 9.44617 12.6559 10.2818ZM9.67681 22.1443V12.8624H12.5615V22.1437H9.67681V22.1443Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8736 15.8248C14.8736 14.667 14.8355 13.68 14.7974 12.8638H17.3029L17.4361 14.1354H17.4931C17.8727 13.5469 18.8216 12.6555 20.359 12.6555C22.2566 12.6555 23.6802 13.9083 23.6802 16.6404V22.1456H20.7955V17.0019C20.7955 15.8054 20.3783 14.9898 19.3344 14.9898C18.537 14.9898 18.0629 15.5402 17.8733 16.0712C17.797 16.2613 17.7595 16.5266 17.7595 16.793V22.1456H14.8748V15.8248H14.8736Z"
      fill="black"
    />
  </svg>
);

export default LinkedDnIcon;
