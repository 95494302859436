import { useState, useEffect } from "react";

// Define the hook with explicit type annotation for 'query'
export default function useMatchMedia(query: string): boolean {
  const [matches, setMatches] = useState<boolean>(
    window.matchMedia(query).matches
  );

  useEffect(() => {
    const media = window.matchMedia(query);
    const listener = () => setMatches(media.matches);

    // Add listener on mount and clean up on unmount
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [query]);

  return matches;
}
