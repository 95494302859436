import React, { useEffect, useState } from "react";
import InnerContent from "./innerContent";

const Layout = () => {
  return (
    <main className={`h-full `}>
      <InnerContent />
    </main>
  );
};

export default React.memo(Layout);
