import React, { useRef } from "react";
import { motion } from "framer-motion";

import SectionContainer from "../../shared-components/SectionContainer";
import HeaderText from "../../shared-components/HeaderText";
import { Button } from "../../shared-components/Button";
import ArrowUpRightIcon from "../../shared-components/icon/ArrowUpRight";
import { Formik, Form, FormikHelpers } from "formik";
import InputField from "../../shared-components/Input";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";
import TextAreaField from "../../shared-components/TextAreaField";
interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  message: string;
}
const initialValues: FormValues = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  message: "",
};
const validationSchema = Yup.object({
  firstname: Yup.string().required("Prénom requis"),
  lastname: Yup.string().required("Nom requis"),
  email: Yup.string().email("Email address Invalid").required("Email  requis"),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Le numéro de téléphone doit être numérique")
    .required("Le numéro de téléphone est requis"),
  message: Yup.string().required("Le message est requis"),
});
const leftVariant = {
  initial: { x: -300, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: -300, opacity: 0 },
};

const rightVariant = {
  initial: { x: 300, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: 300, opacity: 0 },
};

const transition = {
  type: "spring",
  stiffness: 100,
  damping: 20,
};

const Contact = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const sendEmail = (
    values: FormValues,
    { setSubmitting, resetForm }: FormikHelpers<FormValues>
  ) => {
    if (formRef.current) {
      emailjs
        .sendForm(
          process.env.REACT_APP_EMAILJS_SERVICE_ID || "",
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID || "",
          formRef.current,
          process.env.REACT_APP_EMAILJS_USER_ID || ""
        )
        .then(
          (result) => {
            console.log(result.text);

            resetForm();
          },
          (error) => {
            console.log(error.text);
            alert("Failed to send email. Please try again.");
          }
        )
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <SectionContainer>
      <div className="md:pb-32 pb-16 ">
        <div className="grid md:grid-cols-12 md:gap-4 gap-y-14">
          <motion.div
            className="flex flex-col  gap-8 col-span-5"
            variants={leftVariant}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={transition}
          >
            <HeaderText>Dites bonjour</HeaderText>
            <p className="md:text-lg text-sm mb-4">
              Dites-nous en plus sur votre projet ou dites simplement bonjour.
              Vous pouvez également nous envoyer un courriel à.
            </p>
            <div>
              <Button
                icon={<ArrowUpRightIcon />}
                description="contact@mboadigital.tech"
                link="mailto:contact@mboadigital.tech"
                target="_blank"
              />
            </div>
          </motion.div>

          <motion.div
            className="flex flex-col gap-6 col-span-7"
            variants={rightVariant}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={transition}
          >
            <div className="App">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={sendEmail}
              >
                {({ isSubmitting }) => (
                  <Form ref={formRef} className="grid gap-6">
                    <div className="grid md:grid-cols-2 gap-x-5">
                      <InputField name="firstname" label="Prénom" type="text" />
                      <InputField
                        name="lastname"
                        label="Nom de famille"
                        type="text"
                      />
                    </div>
                    <InputField name="email" label="Email" type="text" />
                    <InputField name="phone" label="Téléphone" type="text" />
                    <TextAreaField name="message" label="Message" />
                    <div>
                      <Button
                        description="Envoyer"
                        disabled={isSubmitting}
                        type="submit"
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </motion.div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default React.memo(Contact);
