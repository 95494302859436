import React from "react";
import SectionContainer from "../../shared-components/SectionContainer";
import CaseStudyItem from "./component/CaseStudyItem";
import TitlePage from "../../shared-components/TitlePage";
import { carouselDate } from "../../data/data";

const CaseStudy = () => {
  return (
    <SectionContainer>
      <div className="md:pb-32 pb-16">
        <TitlePage>Cas d'étude</TitlePage>
        <div className="grid gap-12 ">
          {carouselDate.map((study, index) => (
            <CaseStudyItem
              badge={study.badge}
              key={index}
              title={study.title}
              description={study.description}
              imageUrl={study.image}
              link={study.linkCase}
            />
          ))}
        </div>
      </div>
    </SectionContainer>
  );
};

export default React.memo(CaseStudy);
