import React from "react";

const ApproachDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        Pour relever ce défi, nous avons développé et supporté un site web
        innovant, agissant comme un portail centralisé pour diffuser des
        informations vitales. Notre solution inclut :
      </p>
      <div>
        <p className="font-bold">Système de Gestion de Contenu Headless</p>
        <p>
          Une architecture robuste et flexible permettant au personnel du centre
          de santé de mettre à jour facilement les informations destinées aux
          parents, patients, et visiteurs sans compromettre la qualité ou
          l'accessibilité.
        </p>
      </div>
      <div>
        <p className="font-bold">Moteur de Recherche Avancé</p>
        <p>
          Facilite la recherche rapide d'informations cliniques pertinentes pour
          les praticiens dans la région, améliorant ainsi l'efficacité et la
          précision des soins pédiatriques.
        </p>
      </div>
      <div>
        <p className="font-bold">Outils de Calcul Dosage</p>
        <p>
          Des calculateurs innovants pour déterminer les dosages médicamenteux
          appropriés pour les enfants, basés sur l'âge et le poids, soutenant
          ainsi les praticiens dans la prescription précise des traitements.
        </p>
      </div>
    </div>
  );
};

export default ApproachDetails;
