import React, { useEffect, useState } from "react";
import SectionContainer from "../../../shared-components/SectionContainer";
import HeaderText from "../../../shared-components/HeaderText";
import ApproachDetails from "./components/ApproachDetails";
import ResultsDetails from "./components/ResultsDetails";
import ConclusionDetails from "./components/ConclusionDetails";
import GridContainer from "../../../shared-components/GridContainer";
import { Badge } from "../../../shared-components/Badge";
import ZoomImage from "../../../shared-components/ZoomImage";
import { carouselDate } from "../../../data/data";
import IndustryCarousel from "../../../shared-components/IndustryCarousel";
import { Button } from "../../../shared-components/Button";
import ArrowBackIcon from "../../../shared-components/icon/ArrowBack";
import { config } from "react-spring";

import Carousel from "react-spring-3d-carousel";

const Telecommunication = () => {
  let index = 1; // Initialize index for accessing ZindexTable

  let cards = [
    {
      key: 1,
      content: (
        <img
          src="/assets/images/logistique/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 2,
      content: (
        <img
          src="/assets/images/logistique/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 3,
      content: (
        <img
          src="/assets/images/logistique/img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 4,
      content: (
        <img
          src="/assets/images/logistique/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 5,
      content: (
        <img
          src="/assets/images/logistique/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 6,
      content: (
        <img
          src="/assets/images/logistique/img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
  ];

  const [goToSlide, setGoToSlide] = useState(0);
  const slides = cards.map((card, index) => {
    return {
      key: card.key,
      content: (
        <div
          style={{
            transform: goToSlide === index ? "scale(1.5)" : "scale(1)",
            transition: "transform 0.5s ease",
          }}
        >
          {card.content}
        </div>
      ),
      onClick: () => setGoToSlide(index),
      config: {
        scale: goToSlide === index ? 1.6 : 1,
      },
    };
  });

  useEffect(() => {
    // Set up an interval to update the z-index periodically
    const interval1 = setInterval(() => {
      setGoToSlide(index);
      index = (index + 1) % cards.length;
    }, 1875); // Interval set to 3 seconds

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(interval1);
  }, []);
  return (
    <div className="w-full flex flex-col md:gap-32 gap-16 md:pb-32 pb-16 ">
      <SectionContainer>
        <div className="grid md:grid-cols-12">
          <div className="flex flex-col gap-8 col-span-8">
            <div>
              <Button link="/case-studies" icon={<ArrowBackIcon />} />
            </div>

            <div>
              <Badge description="Logistique" />
            </div>

            <HeaderText>
              Réinvention Digitale : Un Nouvel Horizon pour la Livraison de
              Courrier en Océanie
            </HeaderText>
          </div>
        </div>
      </SectionContainer>
      <SectionContainer>
        <div className="grid md:grid-cols-12 ">
          <div className="col-span-4"></div>
          <div className="col-span-8">
            <p className="font-bold mb-6">Contexte</p>
            <p className="md:text-lg text-sm">
              En pleine pandémie de COVID-19, un leader ocanien de la livraison
              de courriers postaux se trouvait à un carrefour critique. La
              maintenance de leur plateforme de poste en ligne, essentielle au
              bon fonctionnement de leurs services, était compromise lorsque le
              prestataire précédent a dû se retirer face aux défis opérationnels
              exacerbés par le long confinement. La continuité des services
              était en jeu, menaçant la satisfaction client et l'efficacité
              opérationnelle.
            </p>
          </div>
        </div>
      </SectionContainer>
      <SectionContainer>
        <img
          src="/assets/images/logistic.png"
          alt="antene"
          className="max-w-full w-full h-auto"
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Approche"
          secondChildren={
            <>
              <p className=" mb-4">
                MBOA DIGITAL c’est avant tout une entreprise agile, qui
                recherche des solutions aux problématiques de nos clients,
                quelles qu’elles soient. Disposer de développeurs formés, de
                process matures, d’un service desk expérimenté sur parcs
                d’envergure, nous a permis, en l’espace de quelques jours, de
                mettre sur place une équipe dédiée pour notre client. Nous avons
                organisé le transfert d’information vers notre service desk qui
                a repris le support.{" "}
              </p>
              <p>
                Forts d'une expertise en support applicatif sur de vastes
                infrastructures, nous avons initié un virage stratégique,
                implémentant des processus clairs et formant les équipes à cette
                nouvelle dynamique. Notre intervention visait non seulement à
                restaurer la stabilité mais aussi à propulser la plateforme vers
                de nouveaux sommets d'innovation et d'excellence opérationnelle.
              </p>
            </>
          }
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer title="Solution" secondChildren={<ApproachDetails />} />
      </SectionContainer>
      <SectionContainer>
        <div className="h-[400px] md:block hidden ">
          <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={2}
            showNavigation={false}
            animationConfig={config.gentle}
          />
        </div>

        <div className="flex flex-col md:gap-12 gap-4 md:hidden">
          <img
            src="/assets/images/logistique/img1.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/logistique/img1.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/logistique/img1.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
        </div>
      </SectionContainer>
      <SectionContainer>
        <GridContainer title="Résultats" secondChildren={<ResultsDetails />} />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Conclusion"
          secondChildren={<ConclusionDetails />}
        />
      </SectionContainer>
      <SectionContainer>
        <div className="grid md:grid-cols-12 mb-12">
          <div className="md:col-span-7  grid md:gap-12 gap-9">
            <h5 className="text-[32px] leading-[40px] ">Travaux connexes</h5>
          </div>
        </div>
        <IndustryCarousel items={carouselDate} />
      </SectionContainer>
    </div>
  );
};

export default React.memo(Telecommunication);
