import React from "react";

const ApproachDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <div>
        <p className="font-bold">Centralisation des Transactions</p>
        <p>
          Notre agrégateur rassemble en temps réel les transactions de
          différents comptes bancaires, offrant une vue holistique de la
          situation financière de l'entreprise.
        </p>
      </div>
      <div>
        <p className="font-bold">Rapprochement Budgétaire Instantané</p>
        <p>
          Les contrôleurs de gestion peuvent désormais ajuster, catégoriser, et
          valider les transactions au fur et à mesure, assurant une conformité
          totale et éliminant les pertes d'informations.
        </p>
      </div>
      <div>
        <p className="font-bold">Reporting Avancé</p>
        <p>
          Grâce à l'agrégation des données, la création de rapports financiers
          devient plus intuitive, permettant une analyse et une prise de
          décision basées sur des données actualisées.
        </p>
      </div>
    </div>
  );
};

export default ApproachDetails;
