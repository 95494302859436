import React from "react";

type HeaderTextProps = {
  children: React.ReactNode;
};

const TitlePage: React.FC<HeaderTextProps> = ({ children }) => (
  <h2 className={`text-[32px] leading-[40px] mb-12 text-neutral-70 `}>
    {children}
  </h2>
);

export default TitlePage;
