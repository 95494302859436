import React from "react";
import DismissIcon from "./icon/Dismiss";
interface AccordionHeaderProps {
  title: string;
  toggleOpen: () => void;
  isOpen: boolean;
}

const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  title,
  toggleOpen,
  isOpen,
}) => (
  <button
    className={`flex group justify-between items-center  w-full focus:outline-none md:py-12 py-8 text-start  transition-all ease-in duration-300 ${
      isOpen ? "pl-12 text-black " : "font-[300] text-neutral-80"
    } `}
    onClick={toggleOpen}
  >
    <span
      className={`md:text-6xl text-[40px] leading-[40px] max-w-[80%]  transition-colors ease-in duration-300 ${
        isOpen ? "text-black font-semibold" : "text-neutral-80"
      } group-hover:text-black  group-hover:font-semibold  `}
    >
      {title}
    </span>
    <div
      className={`transform transition-all ease-in duration-300 ${
        isOpen ? "opacity-100 scale-100" : "opacity-0 scale-95"
      } md:h-[88px] md:w-[88px] h-[52px] w-[52px] bg-black rounded-full flex justify-center items-center text-white`}
    >
      <DismissIcon />
    </div>
  </button>
);

export default AccordionHeader;
