import React from "react";

type HeaderTextProps = {
  children: React.ReactNode;
  white?: boolean;
};

const HeaderText: React.FC<HeaderTextProps> = ({ children, white = false }) => (
  <h2
    className={`lg:leading-[70px] lg:text-[64px] text-[40px] leading-[44px] font-semibold ${
      white ? "text-white" : "text-black"
    }`}
  >
    {children}
  </h2>
);

export default HeaderText;
