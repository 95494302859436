import React from "react";

const ApproachDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        Quelques solutions apportées sur ces deux trois années de collaboration{" "}
      </p>

      <div>
        <h5 className="font-bold">Robustesse</h5>
        <p>
          Nous avons rendu le processus de de paiement plus robuste, en mettant
          à jour le pattern d’intégration avec le payment gateway. Au bout,
          moins de cas de services payés non enregistrés comme tels dans le
          système (et donc non rendus à l’utilisateur, au détriment de ce
          dernier). Nous avons entrepris une refonte de l’architecture AWS, et
          avons implémenté Infrastructure As Code pour l’ensemble de la
          solution. : recréer un environnement opérationnel en cas d’incident
          majeur est maintenant réalisable en quelques minutes.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Optimisation OPEX Cloud</h5>
        <p>
          La refonte de l'architecture des services sur AWS a permis une
          utilisation plus rationnelle des ressources cloud. Grâce à l'adoption
          des meilleures pratiques AWS, une approche infrastructure as code, une
          documentation exhaustive, et une sécurité renforcée, nous avons
          réalisé une infrastructure plus performante et économique.
        </p>
        <p>
          A titre d’exemple, remplacement d’instances hébergeant des sites
          statiques par du stockage. Unification d’un service déployé en double
          pour servir deux API différentes, et configuration de deux APIs
          pointant sur une seule instance de service, tout en renforçant les
          règles d’accès.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Sécurité</h5>
        <p>
          Suite à un ensemble de recommandations que nous avons fait validé et
          réalisé, nous avons mené, avec le département sécurité, un audit PCI
          DSS. Ce dernier n’a révélé que quelques points mineurs qui ont été
          réglés.
        </p>
      </div>
      <div>
        <h5 className="font-bold">Ouverture et Accessibilité</h5>
        <p>
          Nous avons élargi les fonctionnalités de la plateforme pour permettre
          aux entreprises de s'enregistrer, réaliser des déménagements, et
          rediriger leur courrier, étendant des services jusqu'alors réservés
          aux particuliers.
        </p>
      </div>
    </div>
  );
};

export default ApproachDetails;
