import React from "react";

import { Footer } from "../../shared-components/Footer";
import Header from "../../shared-components/Header";
import ScrollToTopButton from "../../shared-components/ScrollToTopButton";

interface Iprop {
  content?: JSX.Element | null;
}

const MainLayout = (props: Iprop) => {
  const { content } = props;
  return (
    <div className="flex flex-col min-h-screen w-screen md:text-lg text-sm font-[274] relative  ">
      <Header />
      {/* Ensure the content area can grow to fill the space */}
      <main className="flex-grow md:pt-[200px] pt-[120px] ">
        {" "}
        <ScrollToTopButton /> {content}
      </main>
      <Footer />
    </div>
  );
};

MainLayout.defaultProps = {
  content: null,
};

export default React.memo(MainLayout);
