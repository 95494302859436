// StaffMember.tsx
import React, { useEffect, useRef, useState } from "react";
import CamerounFlagIcon from "../../../shared-components/icon/CamerounFlag";
import LinkedDnIcon from "../../../shared-components/icon/LinkedDn";
import { Button } from "../../../shared-components/Button";
import ArrowRightIcon from "../../../shared-components/icon/ArrowRight";
import DismissIcon from "../../../shared-components/icon/Dismiss";
import ArrowUpRightIcon from "../../../shared-components/icon/ArrowUpRight";

interface StaffMemberProps {
  name: string;
  title: string;
  description: string;
  imageUrl: string;
  imageMobUrl: string;
  link: string;
  country: string;
}

const StaffMember: React.FC<StaffMemberProps> = ({
  name,
  title,
  description,
  imageUrl,
  link,
  country,
  imageMobUrl,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef<HTMLParagraphElement>(null);

  const toggleModal = () => setModalIsOpen(!modalIsOpen);
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Function to stop propagation for inside modal clicks
  const handleModalClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
  };
  const modalRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    // Function to handle click outside
    const handleClickOutside = (event: MouseEvent) => {
      // Ensure modalRef is current and the modal is open
      // Asserting event.target as Node
      if (
        modalIsOpen &&
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        closeModal();
      }
    };

    if (modalIsOpen) {
      // Add event listener when the modal is open
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      // Remove event listener when the modal is closed
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalIsOpen]); // Depend on modalIsOpen to add/remove the event listener

  useEffect(() => {
    const checkOverflow = () => {
      const current = descriptionRef.current;
      if (current) {
        setIsOverflowing(current.scrollHeight > current.clientHeight);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow); // Also check on window resize

    return () => window.removeEventListener("resize", checkOverflow);
  }, [description]);
  return (
    <div className="flex relative  flex-col md:flex-row justify-center items-center md:items-start  md:justify-start gap-5">
      <img
        src={imageUrl}
        alt={name}
        className="md:w-[192px] md:h-[253px]  md:block hidden "
      />
      <img
        src={imageMobUrl}
        alt={name}
        className=" w-[143px] h-[188px] md:hidden block "
      />
      <div className="flex flex-col  gap-4">
        <div className="hidden md:block ">
          <div className="flex gap-4 items-center">
            <div className="text-2xl font-semibold">{name}</div>
            <img src={country} alt={country} className="w-[27px] h-[18px]  " />
            <div>
              <button
                className="cursor-pointer outline-none flex justify-center items-center"
                onClick={() => window.open(link, "_blank")}
              >
                <LinkedDnIcon />
              </button>
            </div>
          </div>
          <div className="text-lg mt-2">{title}</div>
        </div>
        <div className="flex flex-col gap-2 justify-center items-center md:hidden">
          <div className="text-2xl font-semibold">{name}</div>
          <div className="text-sm ">{title}</div>
          <div className="flex gap-4 items-center">
            <img src={country} alt={country} className="w-[27px] h-[18px]  " />
            <button
              className="cursor-pointer outline-none flex justify-center items-center"
              onClick={() => window.open(link, "_blank")}
            >
              <LinkedDnIcon />
            </button>
          </div>
        </div>
        <div className="lg:max-h-60 ma overflow-hidden h-full">
          <p
            ref={descriptionRef}
            className="text-sm md:text-lg text-center md:text-start lg:line-clamp-5 mb-8"
          >
            {description}{" "}
          </p>
          {isOverflowing && (
            <Button
              description="Voir Plus"
              icon={<ArrowUpRightIcon />}
              onClick={toggleModal}
            />
          )}
          {modalIsOpen && (
            <p
              ref={modalRef}
              className="text-sm md:text-lg text-center md:text-start absolute top-0 bg-white shadow-md z-50 p-4"
            >
              {description}{" "}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffMember;
