import { StaffMemberType } from "../../../type/Type";

export const staffMembers: StaffMemberType[] = [
  {
    name: "Habib Tafo",
    title: "Associé, Directeur Général",
    description:
      "Habib Tafo est un consultant numérique avec plus de 15 ans d'expérience en livraison de solutions logicielles et conseil en transformation numérique. Il a réalisé des projets d’envergure nationale au sein de la DSI de Pôle Emploi, puis en Nouvelle-Zélande, avant de créer MBOA DIGITAL.",
    imageUrl: "/assets/images/teams/img1.svg",
    imageMobUrl: "/assets/images/teams/img1-mob.svg",
    link: "https://cm.linkedin.com/in/htafo/fr",
    county: "/assets/images/country-flag/france.svg",
  },
  {
    name: "Ernest Mougoue",
    title: "Associé, Expert en Cybersécurité",
    description:
      "Ernest est un leader en cybersécurité qui a commencé sa carrière en effectuant des évaluations en tant que consultant avant de construire et de diriger des programmes de sécurité dans des organisations des secteurs technologique et de la fintech. Après avoir dirigé l'équipe de sécurité des produits chez Pinterest, il a rejoint MBOA DIGITAL en tant qu’associé pour porter plus haut notre ambition de créer des solutions robustes et sécurisées.",
    imageUrl: "/assets/images/teams/img2.svg",
    imageMobUrl: "/assets/images/teams/img2-mob.svg",
    link: "https://www.linkedin.com/in/emougoue/",
    county: "/assets/images/country-flag/cameroon.svg",
  },
  {
    name: "Samuel Nchinda",
    title: "Associé, Board Advisor",
    description:
      "Samuel est un cadre supérieur chez Orange Business Service. Avec plus de 24 ans d'expérience dans la fourniture de solutions globales aux grandes entreprises, il occupe désormais le poste de vice-président des Solutions Orange Amérique, avec un fort accent sur la conquête du marché américain. Il apporte son expérience à MBOA DIGITAL pour construire un fournisseur de service global.",
    imageUrl: "/assets/images/teams/img3.svg",
    imageMobUrl: "/assets/images/teams/img3-mob.svg",
    link: "https://www.linkedin.com/in/samuel-nchinda-545b892",
    county: "/assets/images/country-flag/usa.svg",
  },
  {
    name: "Ariel Tchougang",
    title: "Tech Lead, Formateur",
    description:
      "Ariel est un ingénieur logiciel expérimenté. Il a passé plus d’une dizaine d’années à livrer des solutions pour de grands comptes avant d’embrasser un parcours de formateur cloud AWS. Ariel est pleinement investi dans le projet MBOA DIGITAL et apporte son expérience sur les volets expertise des projets, ainsi que la formation cloud des consultants.",
    imageUrl: "/assets/images/teams/img4.svg",
    imageMobUrl: "/assets/images/teams/img4-mob.svg",
    link: "https://www.linkedin.com/in/tchougang/",
    county: "/assets/images/country-flag/france.svg",
  },
  {
    name: "Valery Melou",
    title: "Delivery Lead",
    description:
      "Valéry, développeur web full-stack exceptionnel et atypique, incarne l'essence de la jeunesse dynamique qui caractérise MBOA DIGITAL. Issu d'un parcours autodidacte, il a perfectionné ses compétences en développant pour divers clients en tant que freelance sur des plateformes en ligne avant de s'engager avec MBOA DIGITAL. Au fil des années, Valéry s'est révélé être un pilier crucial de l'entreprise, renforçant la satisfaction des clients grâce à son leadership proactif et à sa rigueur.",
    imageUrl: "/assets/images/teams/img5.svg",
    imageMobUrl: "/assets/images/teams/img5-mob.svg",
    link: "https://cm.linkedin.com/in/valerymelou",
    county: "/assets/images/country-flag/cameroon.svg",
  },
  {
    name: "Divine Bah",
    title: "People & Culture Lead",
    description:
      "Divine Bah est un leader expérimenté avec plus d'une décennie d'expérience au sein de plusieurs startups qu’il a cofondées. Il détient un doctorat en sciences humaines et des capacités de management hors pair. MBOA DIGITAL est son dernier challenge, et pour y arriver il a suivi un brillant cursus de réorientation, avec des cours et certifications ITIL 4, AWS, Azure, Scrum… Il est maintenant responsable de cultiver une dynamique d’excellence au sein de l’entreprise.",
    imageUrl: "/assets/images/teams/img6.svg",
    imageMobUrl: "/assets/images/teams/img6-mob.svg",
    link: "https://cm.linkedin.com/in/bondfleet",
    county: "/assets/images/country-flag/cameroon.svg",
  },
];
