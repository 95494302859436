import React, { useEffect, useState } from "react";
import SectionContainer from "../../../shared-components/SectionContainer";
import HeaderText from "../../../shared-components/HeaderText";
import ApproachDetails from "./components/ApproachDetails";
import GridContainer from "../../../shared-components/GridContainer";
import { Badge } from "../../../shared-components/Badge";
import { carouselDate } from "../../../data/data";
import IndustryCarousel from "../../../shared-components/IndustryCarousel";
import { Button } from "../../../shared-components/Button";
import ArrowBackIcon from "../../../shared-components/icon/ArrowBack";
import { config } from "react-spring";

import Carousel from "react-spring-3d-carousel";

const Rma = () => {
  let index = 1; // Initialize index for accessing ZindexTable

  let cards = [
    {
      key: 1,
      content: (
        <img
          src="/assets/images/rma/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 2,
      content: (
        <img
          src="/assets/images/rma/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 3,
      content: (
        <img
          src="/assets/images/rma/img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 4,
      content: (
        <img
          src="/assets/images/rma/img1.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 5,
      content: (
        <img
          src="/assets/images/rma/img2.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
    {
      key: 6,
      content: (
        <img
          src="/assets/images/rma/img3.svg"
          alt="antene"
          className="max-w-full w-full h-auto "
        />
      ),
    },
  ];

  const [goToSlide, setGoToSlide] = useState(0);
  const slides = cards.map((card, index) => {
    return {
      key: card.key,
      content: (
        <div
          style={{
            transform:
              goToSlide === index
                ? index === 1 || index === 4
                  ? "scale(1.1)"
                  : "scale(1.5)"
                : "scale(1)",
            transition: "transform 0.5s ease",
          }}
        >
          {card.content}
        </div>
      ),
      onClick: () => setGoToSlide(index),
      config: {
        scale: goToSlide === index ? 1.6 : 1,
      },
    };
  });

  useEffect(() => {
    // Set up an interval to update the z-index periodically
    const interval1 = setInterval(() => {
      setGoToSlide(index);
      index = (index + 1) % cards.length;
    }, 1875); // Interval set to 3 seconds

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => clearInterval(interval1);
  }, []);
  return (
    <div className="w-full flex flex-col md:gap-32 gap-16 md:pb-32 pb-16 ">
      <SectionContainer>
        <div className="grid md:grid-cols-12">
          <div className="flex flex-col gap-8 col-span-8">
            <div>
              <Button link="/case-studies" icon={<ArrowBackIcon />} />
            </div>

            <div>
              <Badge description="Electronique" />
            </div>

            <HeaderText>
              Révolution dans la Gestion des Retours : Un Système de RMA
              Innovant pour un Géant des Télécoms
            </HeaderText>
          </div>
        </div>
        <div className="grid md:grid-cols-12  mt-12">
          <div className="md:col-span-4"></div>
          <div className="md:col-span-8">
            <p className="font-bold mb-6">Contexte</p>
            <p>
              Un leader européen du secteur des télécommunications était
              confronté à un défi logistique majeur : centraliser et suivre
              efficacement les autorisations de retour de marchandises (RMAs)
              pour l'un de ses principaux sites de réparation. La diversité des
              sources de RMAs et la nécessité d'une gestion fluide
              représentaient des obstacles notables à l'efficacité et à la
              satisfaction client.
            </p>
          </div>
        </div>
      </SectionContainer>

      <SectionContainer>
        <img
          src="/assets/images/logistique1.svg"
          alt="antene"
          className="max-w-full w-full h-auto"
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Approche"
          secondChildren={
            <p>
              Pour accompagner notre client sur cette problématique, nous nous
              sommes appuyés sur l’expérience de notre équipe sur les
              problématiques de logistique. Face au manque d’engagement des
              métiers, nous avons impliqué davantage notre user experience
              designer. Il est plus facile de recueillir des retours sur la base
              de prototypes développés à faible coût, plutôt que des
              aller-retours de documents à valider. Malgré la faible implication
              des métiers, nous avons pu mener à bien la phase d’analyse en
              étant force de proposition sur les solutions. Et procéder à la
              réalisation.
            </p>
          }
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer title="Solution" secondChildren={<ApproachDetails />} />
      </SectionContainer>
      <SectionContainer>
        <div className="h-[400px] md:block hidden ">
          <Carousel
            slides={slides}
            goToSlide={goToSlide}
            offsetRadius={2}
            showNavigation={false}
            animationConfig={config.gentle}
          />
        </div>

        <div className="flex flex-col md:gap-12 gap-4 md:hidden">
          <img
            src="/assets/images/rma/img1.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/rma/img2.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
          <img
            src="/assets/images/rma/img3.svg"
            alt="antene"
            className="max-w-full w-full h-auto "
          />
        </div>
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Résultats"
          secondChildren={
            <p>
              Notre système de gestion de RMAs a radicalement transformé la
              capacité du client à gérer jusqu'à 100 000 RMAs quotidiennement,
              marquant une amélioration significative dans la vitesse de
              traitement et la capacité de suivi et de contrôle des retours. La
              mise en place de rapports détaillés a également permis une
              meilleure compréhension des tendances et des points de friction,
              facilitant ainsi l'amélioration continue des processus.
            </p>
          }
        />
      </SectionContainer>
      <SectionContainer>
        <GridContainer
          title="Conclusion"
          secondChildren={
            <p>
              En développant une solution de gestion des RMAs sur mesure pour un
              acteur clé du secteur des télécoms, nous avons non seulement
              relevé un défi logistique complexe mais avons également posé les
              bases d'une gestion des retours plus efficace, transparente et
              centrée sur l'utilisateur. Ce système illustre notre capacité à
              transformer les défis opérationnels en opportunités d'innovation
              et d'excellence.
            </p>
          }
        />
      </SectionContainer>
      <SectionContainer>
        <div className="grid md:grid-cols-12 mb-12">
          <div className="md:col-span-7  grid md:gap-12 gap-9">
            <h5 className="text-[32px] leading-[40px] ">Travaux connexes</h5>
          </div>
        </div>
        <IndustryCarousel items={carouselDate} />
      </SectionContainer>
    </div>
  );
};

export default React.memo(Rma);
