import React from "react";

const ApproachDetails = () => {
  return (
    <div className="grid gap-8 md:text-lg text-sm">
      <p>
        Pour pallier ces difficultés, Payrollr a été conçu comme l'outil de
        gestion de paie par excellence, intégrant pleinement les spécificités du
        contexte camerounais. Payrollr vise à simplifier la gestion de la paie
        pour les chefs d'entreprise, les employés, les comptables et
        l'administration, en offrant une solution accessible, pratique et
        conforme aux réglementations locales.
      </p>
      <div>
        <p className="font-bold">Pour les Chefs d’Entreprise</p>
        <p>
          Payrollr offre une maîtrise totale de la masse salariale depuis
          n'importe quel appareil, assurant la conformité et réduisant les
          risques financiers lors des contrôles.
        </p>
      </div>
      <div>
        <p className="font-bold">Pour les Employés</p>
        <p>
          La plateforme transforme l'expérience des employés, leur permettant de
          gérer leurs congés, consulter leurs fiches de paie ou encore
          solliciter des prêts employeur facilement, comparable à l'utilisation
          d'un réseau social depuis leur mobile ou ordinateur.
        </p>
      </div>
      <div>
        <p className="font-bold">Pour les Comptables</p>
        <p>
          Payrollr enrichit le rôle des comptables en intégrant un expert de la
          paie au sein de l'entreprise, tout en générant des journaux de paie
          conformes pour une intégration automatique dans les systèmes
          comptables existants.
        </p>
      </div>
      <div>
        <p className="font-bold">Pour l'Administration</p>
        <p>
          La solution facilite l'intégration avec des entités telles que la
          CNPS, permettant une meilleure couverture et un recouvrement des
          cotisations sociales plus efficace, bénéficiant ainsi à toutes les
          tailles d'entreprises et même aux particuliers employeurs.
        </p>
      </div>
    </div>
  );
};

export default ApproachDetails;
