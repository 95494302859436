import { Navigate, useRoutes } from "react-router-dom";
import Layout from "../shared-components/layout/Layout";
import PublicRoutes from "./PublicRoutes";
import Home from "../pages/home/Home";
import AboutUs from "../pages/aboutUs/AboutUs";
import Telecomunication from "../pages/caseStudy/telecomunication/Telecomunication";
import Contact from "../pages/contact-us/Contact";
import Teams from "../pages/aboutUs/team/Teams";
import CaseStudy from "../pages/caseStudy/CaseStudy";
import Pricing from "../pages/pricing/Pricing";
import Logistique from "../pages/caseStudy/logistique/Logistique";
import ScrollToTop from "./ScrollTotop";
import LegalMention from "../pages/legal-mention/LegalMention";
import Rma from "../pages/caseStudy/rma/Rma";
import Ola from "../pages/caseStudy/autoGestion/AutoGestion";
import Payrollr from "../pages/caseStudy/payrollr/Payrollr";
import DigiPediaMed from "../pages/caseStudy/digiPediaMed/DigiPediaMed";
import AutoGestion from "../pages/caseStudy/autoGestion/AutoGestion";

const MainRoutes = () => {
  return (
    <>
      <ScrollToTop />
      {useRoutes([
        {
          path: "/",
          element: <PublicRoutes />,
          children: [
            {
              path: "/",
              element: <Layout />,
              children: [
                { path: "/", element: <Navigate replace to="home" /> },
                { path: "home", element: <Home /> },
                { path: "about-us", element: <AboutUs /> },
                { path: "contact-us", element: <Contact /> },
                { path: "pricing", element: <Pricing /> },
                {
                  path: "case-studies",
                  element: <CaseStudy />,
                },
                {
                  path: "case-studies/payrollr",
                  element: <Payrollr />,
                },
                {
                  path: "case-studies/telecomunication",
                  element: <Telecomunication />,
                },
                {
                  path: "case-studies/lco",
                  element: <Logistique />,
                },
                {
                  path: "case-studies/digipediaMed",
                  element: <DigiPediaMed />,
                },
                {
                  path: "case-studies/electronic-rma",
                  element: <Rma />,
                },
                {
                  path: "case-studies/autoGestion",
                  element: <AutoGestion />,
                },
                {
                  path: "about-us",
                  children: [
                    { path: "mission", element: <AboutUs /> },
                    { path: "teams", element: <Teams /> },
                  ],
                },
                {
                  path: "legal-mention",
                  element: <LegalMention />,
                },
              ],
            },
          ],
        },
      ])}
    </>
  );
};

export default MainRoutes;
